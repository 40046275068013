<template>
  <div>
    <b-row class="justify-content-md-center mt-7">
      <b-col cols="3" class="text-center">
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          class="w-75 mx-auto"
          :placeholder="$t(`admin.form.${field.id}.placeholder`)"
        ></b-form-input>
      </b-col>
      <b-col cols="3" class="text-center">
        <b-button class="btn" @click="selectAll">
          {{ $t("admin.form.select_all") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center mt-5">
      <b-col cols="9">
        <b-table
          class="w-100 text-left"
          :items="filtered"
          :fields="field.fields || []"
          :current-page="actualPage"
          :per-page="field.perPage || 5"
        >
          <template #cell(selected)="row">
            <b-form-checkbox v-model="mutation" :value="row.item.id" switch />
          </template>
        </b-table>
      </b-col>
      <b-col cols="9" class="text-center mt-3">
        <b-pagination
          v-model="actualPage"
          :total-rows="filtered.length"
          :per-page="field.perPage || 5"
          align="fill"
          size="md"
          class="ml-auto mr-auto w-50"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "TableForm",
  props: {
    field: {
      required: true
    },
    value: {
      required: true
    }
  },
  data() {
    return {
      actualPage: 1,
      filter: "",
      mutation: []
    };
  },
  mounted() {
    this.rows = this.field.options;
    this.mutation = this.value;
  },
  methods: {
    selectAll() {
      if (this.mutation.length < this.filtered.length) {
        this.mutation = _.map(this.filtered, "id");
      } else {
        this.mutation = [];
      }
    }
  },
  watch: {
    mutation(value) {
      this.$emit("changedValue", { field: this.field, value: value });
    }
  },
  computed: {
    filtered() {
      let result = this.field.options;
      if (this.filter !== "") {
        const searchTerms = this.filter
          .split(";")
          .filter(term => term != "")
          .map(term => term.trim().toLowerCase());

        // Filter the result array
        result = result.filter(tag => {
          // Check if any of the search terms match any of the user's properties
          return searchTerms.some(term =>
            tag.email.toLowerCase().includes(term)
          );
        });
      }

      return result;
    }
  }
};
</script>
