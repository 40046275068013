import axios from "axios";
import _ from "lodash";

import {
  getUsers,
  getDepartments,
  getCompanies,
  getAdminStakeholder,
  getUserAdminCompanies
} from "@/api/admin/stakeholders.api";
import { getConstants } from "@/api/misc.api";

const state = {
  companySelected: JSON.parse(localStorage.getItem("companySelected")) || false,
  companies: [],
  users: [],
  departments: [],
  companiesChildren: [],
  chunkIndex: 0,
  status: {
    companySelected: false,
    companies: false,
    users: false,
    departments: false,
    companiesChildren: false
  }
};

const getters = {
  companySelected: state => state.companySelected,
  companies: state => state.companies,
  users: state => state.users,
  departments: state => state.departments,
  companiesChildren: state => state.companiesChildren,
  isCompanySelectedLoaded: state => state.status.companySelected === "success",
  areAdminCompaniesLoaded: state => state.status.companies === "success",
  areUsersLoaded: state => state.status.users === "success",
  areDepartmentsLoaded: state => state.status.departments === "success",
  areCompaniesChildrenLoaded: state =>
    state.status.companiesChildren === "success"
};

const actions = {
  loadCompanyUpdated({ commit }, stakeholderId) {
    axios
      .all([
        getAdminStakeholder("companies", stakeholderId, {
          stakeholderId: true,
          name: true,
          logo: true
        })
      ])
      .then(
        axios.spread(companyUpdatedResp => {
          commit("set_company_updated", companyUpdatedResp.data.records);
        })
      );
  },
  loadCompanySelected({ commit, dispatch }) {
    const stakeholderId = localStorage.getItem("managedCompanyId");

    axios
      .all([
        getAdminStakeholder("companies", stakeholderId, {
          stakeholderId: true,
          name: true,
          domain: true,
          code: true,
          logo: true,
          environment: true,
          services: true
        }),
        getConstants("SERVICETYPE_")
      ])
      .then(
        axios.spread((companySelectedResp, servicesResp) => {
          let services = servicesResp.data.records;
          let data = companySelectedResp.data.records;
          commit("set_company_selected", data);
          commit("set_done", "companySelected");

          dispatch(
            "Auth/setServices",
            {
              services: services,
              userServices: data.services
            },
            { root: true }
          );
        })
      );
  },
  convertArrayToChunks(array, size) {
    const chunks = [];

    for (let i = 0; i < array.length; i += size) {
      const chunk = array.slice(i, i + size);
      chunks.push(chunk);
    }

    return chunks;
  },
  loadAdminCompanies({ commit }) {
    const companyId = localStorage.getItem("stakeholderId");
    axios
      .all([
        getUserAdminCompanies(companyId, {
          stakeholderId: true,
          name: true,
          subdomain: true,
          code: false,
          logo: true
        })
      ])
      .then(
        axios.spread(companies => {
          commit("set_companies", companies.data.records);
          commit("set_done", "companies");
        })
      );
  },
  loadUsers({ commit }) {
    const stakeholderId = localStorage.getItem("managedCompanyId");
    axios
      .all([
        getUsers("companies", stakeholderId, {
          stakeholderDepartmentParentName: true,
          stakeholderDepartmentId: true,
          avatar: true,
          email: true,
          authentication: true,
          locale: true,
          timezone: true,
          phoneNumber: true,
          environment: true,
          login: true,
          tags: true
        })
      ])
      .then(
        axios.spread(users => {
          commit("set_users", users.data.records);
          commit("set_done", "users");
        })
      );
  },
  loadDepartments({ commit }) {
    const stakeholderId = localStorage.getItem("managedCompanyId");
    axios
      .all([
        getDepartments(stakeholderId, {
          stakeholderDepartmentParentName: true
        })
      ])
      .then(
        axios.spread(departments => {
          commit("set_departments", departments.data.records);
          commit("set_done", "departments");
        })
      );
  },
  loadCompaniesChildren({ commit }) {
    const companyId = localStorage.getItem("managedCompanyId");
    axios
      .all([
        getCompanies(companyId, {
          stakeholderId: true,
          name: true,
          domain: true,
          code: true,
          logo: true
        })
      ])
      .then(
        axios.spread(companiesChildren => {
          commit("set_companiesChildren", companiesChildren.data.records);
          commit("set_done", "companiesChildren");
        })
      );
  },
  setCompany({ commit }, data) {
    commit("set_company_selected", data);
    commit("set_done", "companySelected");
  },
  reset({ commit }) {
    commit("reset");
  },
  resetCompanies({ commit }) {
    commit("resetCompanies");
  },
  resetUsers({ commit }) {
    commit("resetUsers");
  },
  resetDepartments({ commit }) {
    commit("resetDepartments");
  }
};

const mutations = {
  set_company_updated(state, data) {
    state.companies = _.map(state.companies, company => {
      return company.stakeholderId == data.stakeholderId ? data : company;
    });
  },
  set_chunk_index(state) {
    state.chunkIndex++;
  },
  set_companies(state, data) {
    state.companies = _.concat(state.companies, data);
  },
  set_company_selected(state, data) {
    state.companySelected = data;
  },
  set_users(state, data) {
    state.users = data;
  },
  set_companiesChildren(state, data) {
    state.companiesChildren = data;
  },
  set_departments(state, data) {
    state.departments = data;
  },
  set_done(state, param) {
    state.status[param] = "success";
  },
  reset(state) {
    state.companySelected = false;
    state.users = [];
    state.departments = [];
    state.companiesChildren = [];
    state.status = {
      companySelected: false,
      companies: false,
      users: false,
      departments: false
    };
  },
  resetCompanies(state) {
    state.companies = [];
    state.status.companies = false;
  },
  resetUsers(state) {
    state.users = [];
    state.status.users = false;
  },
  resetDepartments(state) {
    state.departments = [];
    state.status.departments = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
