<template>
  <div id="tagValues" v-if="tagValues !== false">
    <div class="subheader pb-2 pb-lg-4 subheader-transparent" id="kt_subheader">
      <div
        class="
              container-fluid
              d-flex
              align-items-center
              justify-content-between
              flex-wrap flex-sm-nowrap
            "
      >
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            {{ $t("admin.tags.detail", { name: tag.name }) }}
          </h5>
          <!--end::Title-->
          <!--begin::Separator-->
          <div
            class="
                  subheader-separator subheader-separator-ver
                  mt-2
                  mb-2
                  mr-5
                  bg-gray-200
                "
          ></div>
          <!--end::Separator-->
          <!--begin::Search Form-->
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <span class="text-dark-50 font-weight-bold" id="kt_subheader_total"
              >{{ total }}
              {{
                $t("tables.total", {
                  module: $tc("tables.tagValue", total).toLowerCase()
                })
              }}</span
            >
            <div class="ml-5">
              <div
                class="input-group input-group-sm input-group-solid"
                style="max-width: 175px"
              >
                <input
                  type="text"
                  class="form-control"
                  id="search"
                  v-model="search"
                  :placeholder="$t('tables.search') + ' ...'"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <span class="svg-icon">
                      <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/General/Search.svg-->
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
                      <!--end::Svg Icon-->
                    </span>
                    <!--<i class="flaticon2-search-1 icon-sm"></i>-->
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Search Form-->
        </div>
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
          <b-button
            @click="exportTagsExcel"
            v-b-tooltip.bottom.hover
            :disabled="loadingExcel"
            :title="$t('tables.download')"
            variant="light-primary"
            class="ml-2 font-weight-bold"
          >
            <span class="svg-icon svg-icon-light-primary p-0">
              <simple-svg
                v-if="!loadingExcel"
                :src="require('@/assets/images/icons/downloaded-file.svg')"
              />
              <hollow-dots-spinner
                v-if="loadingExcel"
                :animation-duration="2000"
                :dot-size="6"
                :dots-num="3"
                :color="'#0a2f24'"
              />
            </span>
          </b-button>

          <b-button
            @click="
              $router.push({
                name: 'TagValueCreate',
                params: { id: $route.params.id }
              })
            "
            variant="light-success"
            class="ml-2 font-weight-bold"
            v-b-tooltip.bottom.hover
            :title="$t('tables.create.tagValue')"
          >
            <i class="p-0 fa fa-plus" />
          </b-button>

          <b-button
            @click="$router.push({ name: 'TagsList' })"
            size="sm"
            pill
            variant="secondary"
            class="font-weight-bolder ml-2 text-uppercase"
          >
            <div class="d-flex">
              <i class="fa fa-chevron-left" />
              <span style=" white-space: nowrap">
                {{ $t("generic.buttons.back") }}
              </span>
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <b-card class="card-custom card-stretch card-rounded" no-body>
      <b-card-body>
        <b-table
          id="tags-table"
          hover
          :items="filtered"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          primary-key="id"
          responsive="sm"
          stacked="sm"
          show-empty
          selectable
          ref="selectableTable"
        >
          <template #cell(actions)="row">
            <b-button
              pill
              variant="light-primary"
              class="mr-2 px-auto"
              @click="
                $router.push({
                  name: 'TagValueEdit',
                  params: { id: $route.params.id, value: row.item.value }
                })
              "
            >
              <i class="la la-pencil pl-1" />
            </b-button>
          </template>
        </b-table>
        <div class="flex-wrap mx-auto" v-if="total > 0">
          <b-pagination
            class="d-flex flex-wrap mt-3 py-2 mr-3"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="users-table"
            first-number
            pill
            last-number
            align="center"
            size="lg"
          >
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { getTags, getStakeholders } from "@/api/admin/stakeholders.api";
import * as XLSX from "xlsx";
import { HollowDotsSpinner } from "epic-spinners";

import { SimpleSVG } from "vue-simple-svg";
export default {
  name: "TagsList",
  components: {
    // EmptySearch
    "simple-svg": SimpleSVG,
    "hollow-dots-spinner": HollowDotsSpinner
  },
  data() {
    return {
      tagValues: false,
      tag: false,
      perPage: 10,
      grid: true,
      currentPage: 1,
      search: "",
      allSelected: false,
      selected: [],
      loadingExcel: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    fields() {
      let fields = [
        {
          key: "value",
          label: this.$tc("tables.name", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "count",
          label: this.$tc("tables.count", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          formatter(val, key, item) {
            return _.size(item.people);
          }
        },
        {
          key: "actions",
          label: this.$t("tables.actions"),
          sortable: false,
          class: "align-middle"
        }
      ];
      return _.filter(fields, item => {
        return item.service === undefined || this.hasModule([item.service]);
      });
    },
    total() {
      let tagValues = this.tagValues;
      if (this.search !== "") {
        const searchTerms = this.search
          .split(";")
          .filter(term => term != "")
          .map(term => term.trim().toLowerCase());

        // Filter the tagValues array
        tagValues = tagValues.filter(tag => {
          // Check if any of the search terms match any of the user's properties
          return searchTerms.some(term =>
            tag.value.toLowerCase().includes(term)
          );
        });
      }
      return _.size(tagValues);
    },
    filtered() {
      let tagValues = this.tagValues;
      if (this.search !== "") {
        const searchTerms = this.search
          .split(";")
          .filter(term => term != "")
          .map(term => term.trim().toLowerCase());

        // Filter the tagValues array
        tagValues = tagValues.filter(tag => {
          // Check if any of the search terms match any of the user's properties
          return searchTerms.some(term =>
            tag.value.toLowerCase().includes(term)
          );
        });
      }

      return tagValues;
    },
    excelTags() {
      return _.map(this.filtered, tag => {
        return [tag.value, _.size(tag.people)];
      });
    }
  },
  methods: {
    resetSearch() {
      this.search = "";
    },
    exportTagsExcel() {
      this.loadingExcel = true;
      let stakeholderCompanyId = localStorage.getItem("managedCompanyId");
      let excelUsers = [];
      let stakeholders = [];

      _.each(this.filtered, tag => {
        stakeholders = _.concat(stakeholders, tag.people);
      });

      if (stakeholders.length > 0) {
        getStakeholders(
          "companies",
          stakeholderCompanyId,
          "people",
          {
            stakeholdersId: stakeholders
          },
          { name: true, surname: true, profile: false, email: true }
        ).then(response => {
          _.each(this.filtered, tag => {
            _.each(tag.people, stkId => {
              let person = _.find(
                response.data.records,
                people => people.stakeholderId == stkId
              );
              excelUsers.push({
                first_name: person.name,
                last_name: person.surname,
                email: person.email,
                value: tag.value
              });
            });
          });
          const workbook = XLSX.utils.book_new();

          const tagsWorksheet = XLSX.utils.json_to_sheet(this.excelTags);
          XLSX.utils.book_append_sheet(workbook, tagsWorksheet, "Tags");
          XLSX.utils.sheet_add_aoa(
            tagsWorksheet,
            [[this.$tc("tables.name", 1), this.$tc("tables.count", 1)]],
            { origin: "A1" }
          );

          const usersWorksheet = XLSX.utils.json_to_sheet(excelUsers);
          XLSX.utils.book_append_sheet(workbook, usersWorksheet, "Users");
          XLSX.utils.sheet_add_aoa(
            usersWorksheet,
            [
              [
                this.$tc("tables.name", 1),
                this.$tc("tables.surname", 1),
                this.$tc("tables.email", 1),
                this.$tc("tables.tagValueName", { value: this.tag.name })
              ]
            ],
            { origin: "A1" }
          );

          XLSX.writeFile(workbook, this.tag.name + "_Values.xlsx");
          this.loadingExcel = false;
        });
      }
    }
  },
  mounted() {
    getTags(localStorage.getItem("managedCompanyId"), {
      tagId: this.$route.params.id
    }).then(response => {
      let tag = _.first(response.data.records);
      this.tag = tag;
      this.tagValues = tag.values;
    });
  }
};
</script>
