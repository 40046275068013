<template>
  <div id="users">
    <div class="subheader pb-2 pb-lg-4 subheader-transparent" id="kt_subheader">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Details-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5
            class="text-dark font-weight-bold mt-2 mb-2 mr-5"
            v-if="!byDepartment"
          >
            {{ $t("menu.controller.users.title") }}
          </h5>
          <h5 v-else>
            {{
              $t("menu.controller.users.byDepartment", {
                department: searchDepartment
              })
            }}
          </h5>
          <!--end::Title-->
          <!--begin::Separator-->
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"
          ></div>
          <!--end::Separator-->
          <!--begin::Search Form-->
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <span class="text-dark-50 font-weight-bold" id="kt_subheader_total"
              >{{ total }}
              {{
                $t("tables.total", {
                  module: $tc("tables.user", total).toLowerCase()
                })
              }}</span
            >
            <div class="ml-5">
              <div
                class="input-group input-group-sm input-group-solid"
                style="max-width: 175px"
              >
                <input
                  type="text"
                  class="form-control"
                  id="search"
                  v-model="search"
                  :placeholder="$t('tables.search') + ' ...'"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <span class="svg-icon">
                      <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/General/Search.svg-->
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex align-items-center"
            id="kt_subheader_search"
            v-if="!this.isProfilesLoading"
          >
            <div class="d-flex" v-if="showFilters">
              <div class="ml-5">
                <div class="input-group input-group-sm input-group-solid">
                  <b-form-select
                    class="form-control form-control-solid"
                    v-model="searchTagKeys"
                    :options="tagKeys"
                    @change="searchTags = false"
                  />
                </div>
              </div>
              <div class="ml-5" v-if="searchTagKeys !== false">
                <div class="input-group input-group-sm input-group-solid">
                  <b-form-select
                    class="form-control form-control-solid"
                    v-model="searchTags"
                    :options="tags"
                  />
                </div>
              </div>
            </div>
            <div v-if="hasModule(['risk'])" class="d-flex">
              <div class="ml-5" v-if="showFilters">
                <div class="input-group input-group-sm input-group-solid">
                  <b-form-select
                    class="form-control form-control-solid"
                    v-model="searchGri"
                    :options="griOptions"
                  />
                </div>
              </div>
              <div class="ml-5" v-if="showFilters">
                <div class="input-group input-group-sm input-group-solid">
                  <b-form-select
                    class="form-control form-control-solid"
                    v-model="searchRisk"
                    :options="riskOptions"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="ml-5" v-if="!showFilters">
                <b-button
                  variant="light-primary"
                  class="ml-2 font-weight-bold"
                  v-b-tooltip.right.hover
                  :title="$t('tables.filter')"
                  @click="showFilters = !showFilters"
                >
                  <i class="fas fa-filter" />
                </b-button>
              </div>
              <div class="ml-5" v-if="showFilters">
                <b-button
                  variant="light-primary"
                  class="ml-2 font-weight-bold"
                  v-b-tooltip.right.hover
                  :title="$t('tables.filter')"
                  @click="resetFilters"
                >
                  <i class="fas fa-times" />
                </b-button>
              </div>
            </div>
          </div>
          <!--end::Search Form-->
        </div>
        <!--end::Details-->
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
          <!--begin::Button-->
          <json-excel
            v-if="!this.isProfilesLoading"
            :data="filtered"
            :fields="excelFields"
            v-b-tooltip.left.hover
            :title="$t('tables.download')"
            type="xls"
            name="users"
            class="btn btn-light-primary btn-icon ml-3"
          >
            <span class="svg-icon svg-icon-light-primary px-2">
              <simple-svg
                :src="require('@/assets/images/icons/downloaded-file.svg')"
              />
            </span>
          </json-excel>
          <b-button
            v-if="byDepartment"
            @click="$router.back()"
            variant="secondary"
            class="ml-2 font-weight-bold"
          >
            <div class="d-flex align-items-center">
              <i class="fa fa-chevron-left" />
              <span style=" white-space: nowrap">
                {{ $t("generic.buttons.back") }}
              </span>
            </div>
          </b-button>
          <!--end::Button-->
        </div>
        <!--end::Toolbar-->
      </div>
    </div>
    <b-card
      class="card-custom card-stretch card-rounded"
      no-body
      v-if="!isUsersLoading"
    >
      <b-card-body>
        <b-table
          id="users-table"
          hover
          :items="filtered"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          primary-key="stakeholderId"
          :responsive="true"
          show-empty
        >
          <template>
            <empty-search
              :description="$t(`tables.empty.description`)"
              :buttonText="$t(`tables.empty.button`)"
              :action="resetFilters"
            />
          </template>
          <template #cell(name)="row">
            <div class="row">
              <div class="col-2">
                <avatar
                  class="mt-4"
                  size="sm"
                  :showArchetype="false"
                  :avatar="
                    row.item.avatar && row.item.avatar !== ''
                      ? row.item.avatar
                      : '000-Empty.svg'
                  "
                />
              </div>
              <div class="col-9 text-left">
                <div class="font-weight-bolder">{{ row.value }}</div>
                <div class="mb-2 text-muted font-weight-bold">
                  {{ row.item.stakeholderDepartmentName }}
                </div>
                <div class="d-flex" v-if="!isProfilesLoading">
                  <b-progress
                    class="progress-xs mt-2 mb-2 flex-shrink-0 w-150px w-xl-250px"
                  >
                    <b-progress-bar
                      :max="10"
                      :animated="false"
                      :value="
                        row.item.progress !== false ? row.item.progress : '-'
                      "
                      :style="
                        `background-color: ${getHexByValue(
                          row.item.progress,
                          10
                        )}`
                      "
                      :striped="false"
                    />
                  </b-progress>
                  <span class="font-weight-bold text-dark ml-4">{{
                    $n(row.item.progress / 10, "percent")
                  }}</span>
                </div>
                <div class="d-flex" v-else>
                  <hollow-dots-spinner
                    :animation-duration="3000"
                    :dot-size="10"
                    :dots-num="3"
                    :color="'#0AB783'"
                  />
                </div>
              </div>
            </div>
          </template>
          <template #cell(insider)="row">
            <b-badge
              class="base-full p-3"
              pill
              style="font-size: 1rem;"
              v-if="!isProfilesLoading"
            >
              {{ row.value }}
            </b-badge>
            <hollow-dots-spinner
              :animation-duration="3000"
              :dot-size="10"
              :dots-num="3"
              :color="'#0AB783'"
              v-else
            />
          </template>
          <template #cell(risk)="row">
            <b-badge
              :class="variantByValue(row.item.risk, 6)"
              pill
              class="p-3 font-weight-bolder"
              style="font-size: 1rem;"
              v-if="!isProfilesLoading"
            >
              {{ row.value }} / 6
            </b-badge>
            <hollow-dots-spinner
              :animation-duration="3000"
              :dot-size="10"
              :dots-num="3"
              :color="'#0AB783'"
              v-else
            />
          </template>
          <template #cell(wellbeing)="row">
            <b-badge
              :class="variantByValue(row.item.wellbeing, 10)"
              style="font-size: 1rem;"
              pill
              class="p-3 font-weight-bolder"
              v-if="!isProfilesLoading"
            >
              {{ row.value }} / 10
            </b-badge>
            <hollow-dots-spinner
              :animation-duration="3000"
              :dot-size="10"
              :dots-num="3"
              :color="'#0AB783'"
              v-else
            />
          </template>
          <template #cell(cyber)="row">
            <b-badge
              style="font-size: 1rem;"
              :class="variantByValue(row.item.cyber, 10)"
              pill
              class="p-3 font-weight-bolder"
              v-if="!isProfilesLoading"
            >
              {{ row.value }} / 10
            </b-badge>
            <hollow-dots-spinner
              :animation-duration="3000"
              :dot-size="10"
              :dots-num="3"
              :color="'#0AB783'"
              v-else
            />
          </template>
          <template #cell(actions)="row">
            <b-button
              pill
              variant="light-primary"
              class="mr-2 px-auto"
              @click="viewDetail(row.item.stakeholderId)"
            >
              <i
                class="la la-eye pl-1"
                v-b-tooltip.right.hover
                :title="$t('tables.seeDetail')"
              />
            </b-button>
          </template>
        </b-table>
        <div class="flex-wrap mx-auto" v-if="total > 0">
          <b-pagination
            class="d-flex flex-wrap mt-3 py-2 mr-3"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="users-table"
            first-number
            pill
            last-number
            align="center"
            size="lg"
          ></b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import {
  getClassByValueAndBase,
  getColorByValueAndBase
} from "@/utils/helpers";

import Avatar from "@/components/profile/Avatar.component.vue";
import EmptySearch from "@/components/EmptySearch.component.vue";
import JsonExcel from "vue-json-excel";
import { SimpleSVG } from "vue-simple-svg";
import { HollowDotsSpinner } from "epic-spinners";
import { getTags } from "@/api/controller/stakeholders.api";

export default {
  name: "Users",
  components: {
    Avatar,
    EmptySearch,
    JsonExcel,
    "simple-svg": SimpleSVG,
    HollowDotsSpinner
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      search: "",
      showFilters: false,
      searchRisk: false,
      searchGri: false,
      searchTags: false,
      searchTagKeys: false,
      byDepartment: false,
      searchDepartment: "",
      showing: 12,
      allTags: []
    };
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Users", ["isProfilesLoading", "isUsersLoading", "users"]),
    ...mapGetters("Auth", ["hasModule"]),
    fields() {
      var fields = [
        {
          key: "name",
          label: this.$tc("tables.user", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle",
          formatter: (val, key, item) => {
            return item.name + " " + item.surname;
          }
        },
        {
          key: "insider",
          label: this.$t(`tables.insider`),
          sortable: !this.isProfilesLoading,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          service: "risk",
          formatter: val => {
            return val !== false
              ? this.$t(`tables.dimensionIds.dimension.${val}.DEFAULT.title`)
              : "-";
          }
        },
        {
          key: "risk",
          label: this.$t(`tables.risk`),
          sortable: !this.isProfilesLoading,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          service: "risk",
          formatter: val => {
            return val !== false ? val : "-";
          }
        },
        {
          key: "cyber",
          label: this.$t(`tables.cyber`),
          sortable: !this.isProfilesLoading,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          service: "awareness",
          formatter: val => {
            return val !== false ? this.$n(val) : "-";
          }
        },
        {
          key: "wellbeing",
          label: this.$t(`tables.wellbeing`),
          sortable: !this.isProfilesLoading,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          service: "wellbeing",
          formatter: val => {
            return val !== false ? this.$n(val) : "-";
          }
        },
        {
          key: "actions",
          label: this.$t("tables.actions"),
          sortable: false,
          class: "align-middle"
        }
      ];
      return _.filter(fields, item => {
        return item.service === undefined || this.hasModule([item.service]);
      });
    },
    excelFields() {
      var fields = {};
      fields[this.$tc("tables.name", 1)] = {
        name: "name",
        callback: value => value.name
      };
      fields[this.$tc("tables.surname", 1)] = {
        name: "surname",
        callback: value => value.surname
      };
      fields[this.$tc("tables.email")] = {
        name: "email",
        callback: value => value.email
      };
      fields[this.$tc("tables.department", 1)] = {
        name: "department",
        callback: value => value.stakeholderDepartmentName
      };
      fields[this.$tc("tables.progress")] = {
        name: "progress",
        callback: value =>
          this.$n(value.progress ? value.progress / 10 : 0, "percent")
      };
      if (this.hasModule(["risk"])) {
        fields[this.$t(`tables.insider`)] = {
          name: "insider",
          callback: value =>
            value.insider
              ? this.$t(
                  `tables.dimensionIds.dimension.${value.insider}.DEFAULT.title`
                )
              : "-"
        };
        fields[this.$t(`tables.risk`)] = {
          name: "risk",
          callback: value =>
            value.risk ? `=CONCAT("${value.risk}";"/";"6")` : "-"
        };
      }
      if (this.hasModule(["awareness"])) {
        fields[this.$t(`tables.cyber`)] = {
          name: "cyber",
          callback: value => (value.cyber ? this.$n(value.cyber) : "-")
        };
      }
      if (this.hasModule(["wellbeing"])) {
        fields[this.$t(`tables.wellbeing`)] = {
          name: "wellbeing",
          callback: value => (value.wellbeing ? this.$n(value.wellbeing) : "-")
        };
      }
      return fields;
    },
    total() {
      return _.size(this.filtered);
    },
    tags() {
      let result = [
        {
          value: false,
          text: this.$t("tables.filterBy", {
            column: this.$t("campaigns.form.tagValues.title").toLowerCase()
          })
        }
      ];

      _.each(_.map(this.users, "tags"), e => {
        let element = e[this.searchTagKeys];
        if (element !== undefined) {
          result = _.concat(result, e[this.searchTagKeys]);
        }
      });

      return _.uniq(result);
    },
    tagKeys() {
      let result = [
        {
          value: false,
          text: this.$t("tables.filterBy", {
            column: this.$t("campaigns.form.tagKeys.title").toLowerCase()
          })
        }
      ];
      _.each(this.allTags, e => {
        if (_.size(e.values) > 0) {
          result.push({ value: e.id, text: e.name });
        }
      });

      return result;
    },
    filtered() {
      var users = this.users;
      if (this.byDepartment !== false) {
        users = _.filter(users, user => {
          if (
            parseInt(user.stakeholderDepartmentId) ===
            parseInt(this.$route.query.department)
          ) {
            this.searchDepartment = user.stakeholderDepartmentName;
            return true;
          } else {
            return false;
          }
        });
      }
      if (this.search !== "") {
        let newSearch = this.search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(`\\b${newSearch}`, "i");
        users = _.filter(users, user => {
          return (
            regex.test(user.name + " " + user.surname) ||
            regex.test(user.stakeholderDepartmentName) ||
            regex.test(user.email)
          );
        });
      }
      if (this.searchRisk !== false) {
        users = _.filter(users, user => {
          return parseInt(user.risk) === parseInt(this.searchRisk);
        });
      }
      if (this.searchGri !== false) {
        users = _.filter(users, user => {
          return parseInt(user.insider) === parseInt(this.searchGri);
        });
      }
      if (this.searchTags !== false) {
        users = _.filter(users, user => {
          return _.includes(
            _.flattenDeep(_.values(user.tags[this.searchTagKeys])),
            this.searchTags
          );
        });
      }
      return users;
    },
    riskOptions() {
      var result = [
        {
          value: false,
          text: this.$t("tables.filterBy", {
            column: this.$t("tables.risk").toLowerCase()
          })
        }
      ];
      for (let i = 1; i <= 6; i++) {
        result.push({
          value: i,
          text: this.$t("tables.filters.risk", { value: i })
        });
      }
      return result;
    },
    griOptions() {
      var result = [
        {
          value: false,
          text: this.$t("tables.filterBy", {
            column: this.$t("tables.insider")
          })
        }
      ];
      var insiders = _.keysIn(_.groupBy(this.users, user => user.insider));
      _.each(insiders, insider => {
        if (insider !== "false" && insider !== undefined) {
          result.push({
            value: insider,
            text: this.$t(
              `tables.dimensionIds.dimension.${insider}.DEFAULT.title`
            )
          });
        }
      });
      return result;
    }
  },
  methods: {
    ...mapActions("Users", ["loadUsers"]),
    variantByValue(value, base = 100) {
      return getClassByValueAndBase(value, base, true, true);
    },
    getHexByValue(value, base) {
      return getColorByValueAndBase(value, base);
    },
    viewDetail(stakeholderId) {
      this.$router.push({
        name: "UserDetail",
        params: {
          id: stakeholderId
        }
      });
    },
    resetFilters() {
      this.showFilters = false;
      this.searchRisk = false;
      this.searchGri = false;
      this.searchTags = false;
      this.searchTagKeys = false;
      this.search = "";
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isUsersLoading) this.loadUsers();
    if (this.$route.query.department) this.byDepartment = true;

    getTags(localStorage.getItem("managedCompanyId"), { allstatus: 0 }).then(
      tagsResp => {
        this.allTags = tagsResp.data.records;
      }
    );
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadUsers();
    },
    isUsersLoading(value) {
      console.error("Users are loaded", value);
      // if (this.isConstantsActive && value === false) this.loadUsers();
    },
    isProfilesLoading(value) {
      console.error("Profiles are loaded", value);
    }
  }
};
</script>
