<template>
  <div id="tags" v-if="this.tags.length > 0">
    <div class="subheader pb-2 pb-lg-4 subheader-transparent" id="kt_subheader">
      <div
        class="
              container-fluid
              d-flex
              align-items-center
              justify-content-between
              flex-wrap flex-sm-nowrap
            "
      >
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            {{ $t("admin.menu.tags.title") }}
          </h5>
          <!--end::Title-->
          <!--begin::Separator-->
          <div
            class="
                  subheader-separator subheader-separator-ver
                  mt-2
                  mb-2
                  mr-5
                  bg-gray-200
                "
          ></div>
          <!--end::Separator-->
          <!--begin::Search Form-->
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <span class="text-dark-50 font-weight-bold" id="kt_subheader_total"
              >{{ total }}
              {{
                $t("tables.total", {
                  module: $tc("tables.tags", total).toLowerCase()
                })
              }}</span
            >
            <div class="ml-5">
              <div
                class="input-group input-group-sm input-group-solid"
                style="max-width: 175px"
              >
                <input
                  type="text"
                  class="form-control"
                  id="search"
                  v-model="search"
                  :placeholder="$t('tables.search') + ' ...'"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <span class="svg-icon">
                      <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/General/Search.svg-->
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
                      <!--end::Svg Icon-->
                    </span>
                    <!--<i class="flaticon2-search-1 icon-sm"></i>-->
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Search Form-->
        </div>
      </div>
    </div>
    <b-card class="card-custom card-stretch card-rounded" no-body>
      <b-card-body>
        <b-table
          id="tags-table"
          hover
          :items="filtered"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          primary-key="id"
          responsive="sm"
          stacked="sm"
          show-empty
          selectable
          ref="selectableTable"
        >
          <template #cell(actions)="row">
            <b-button
              pill
              variant="light-primary"
              class="mr-2 px-auto"
              @click="
                $router.push({ name: 'TagView', params: { id: row.item.id } })
              "
            >
              <i class="la la-eye pl-1" />
            </b-button>
          </template>
        </b-table>
        <div class="flex-wrap mx-auto" v-if="total > 0">
          <b-pagination
            class="d-flex flex-wrap mt-3 py-2 mr-3"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="users-table"
            first-number
            pill
            last-number
            align="center"
            size="lg"
          >
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
// import axios from "axios";
import { mapGetters } from "vuex";
import { getTags } from "@/api/admin/stakeholders.api";

// import EmptySearch from "@/components/EmptySearch.component.vue";
// import { SimpleSVG } from "vue-simple-svg";
export default {
  name: "TagsList",
  components: {
    // EmptySearch
  },
  data() {
    return {
      tags: [],
      perPage: 10,
      grid: true,
      currentPage: 1,
      search: "",
      allSelected: false,
      selected: []
    };
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    fields() {
      let fields = [
        {
          key: "name",
          label: this.$tc("tables.name", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "count",
          label: this.$tc("tables.count", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          formatter(val, key, item) {
            return _.sumBy(item.values, element => _.size(element.people));
          }
        },
        {
          key: "actions",
          label: this.$t("tables.actions"),
          sortable: false,
          class: "align-middle"
        }
      ];
      return _.filter(fields, item => {
        return item.service === undefined || this.hasModule([item.service]);
      });
    },
    total() {
      let tags = this.tags;
      if (this.search !== "") {
        const searchTerms = this.search
          .split(";")
          .filter(term => term != "")
          .map(term => term.trim().toLowerCase());

        // Filter the tags array
        tags = tags.filter(tag => {
          // Check if any of the search terms match any of the user's properties
          return searchTerms.some(term =>
            tag.name.toLowerCase().includes(term)
          );
        });
      }
      return _.size(tags);
    },
    filtered() {
      let tags = this.tags;
      if (this.search !== "") {
        const searchTerms = this.search
          .split(";")
          .filter(term => term != "")
          .map(term => term.trim().toLowerCase());

        // Filter the tags array
        tags = tags.filter(tag => {
          // Check if any of the search terms match any of the user's properties
          return searchTerms.some(term =>
            tag.name.toLowerCase().includes(term)
          );
        });
      }

      return tags;
    }
  },
  methods: {
    resetSearch() {
      this.search = "";
    }
  },
  mounted() {
    getTags(localStorage.getItem("managedCompanyId")).then(response => {
      this.tags = response.data.records;
    });
  }
};
</script>
