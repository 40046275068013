<template>
  <div id="kt_body" class="header-fixed header-mobile-fixed page-loading">
    <!--begin::Header Mobile-->
    <div
      id="kt_header_mobile"
      class="header-mobile bg-primary header-mobile-fixed"
    >
      <!--begin::Logo-->
      <router-link :to="{ name: 'Dashboard' }">
        <img
          alt="Logo"
          src="@/assets/images/menu/logo-white.png"
          style="width: 45px  !important"
        />
      </router-link>
      <!--end::Logo-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <b-button
          class="p-0 burger-icon burger-icon-left ml-4"
          variant="link"
          @click="mobileMenu = !mobileMenu"
        >
          <span></span>
        </b-button>
        <b-button
          class="p-0 ml-2"
          id="kt_header_mobile_topbar_toggle"
          variant="link"
          @click="mobilePanel = !mobilePanel"
        >
          <span class="svg-icon svg-icon-xl">
            <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12 11a4 4 0 110-8 4 4 0 010 8z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M3 20.2c.388-4.773 4.262-7.2 8.983-7.2 4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H3.727c-.25 0-.747-.54-.726-.8z" fill="#000" fill-rule="nonzero"/></g></svg>
            <!--end::Svg Icon-->
          </span>
        </b-button>
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Header Mobile-->
    <div class="d-flex flex-column flex-root">
      <!--begin::Page-->
      <div class="d-flex flex-row flex-column-fluid page">
        <!--begin::Wrapper-->
        <div class="d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <!--begin::Header-->
          <div id="kt_header" class="header flex-column header-fixed">
            <!--begin::Top-->
            <!--begin::Container-->
            <div
              class="bg-primary"
              :class="{ 'topbar-mobile-on': mobilePanel }"
            >
              <div
                class="d-flex-column self-align-center align-items-center text-align-center py-2"
              >
                <!--begin::Logo-->
                <router-link :to="{ name: 'Dashboard' }">
                  <img
                    alt="Logo"
                    src="@/assets/images/menu/simplelogo.png"
                    :style="{ 'max-height': '26px' }"
                  />
                </router-link>
                <!--end::Logo-->
              </div>
            </div>
            <div class="header-top bg-white">
              <!--begin::Container-->
              <div
                class="container"
                :class="{ 'topbar-mobile-on': mobilePanel }"
              >
                <!--begin::Left-->
                <div class="d-none d-lg-flex align-items-center mr-3">
                  <!--begin::Logo-->
                  <router-link
                    :to="{ name: 'Dashboard' }"
                    class="mr-20"
                    v-if="company.logo"
                  >
                    <img
                      alt="Logo"
                      class="max-h-60px min-h-60px"
                      :src="`${$baseUrl}${company.logo}`"
                    />
                  </router-link>
                  <!--end::Logo-->
                </div>
                <!--end::Left-->
                <!--begin::Topbar-->
                <div class="topbar d-flex-column self-align-right">
                  <div class="mr-auto mt-5 d-lg-none">
                    <b-button
                      class="p-0 mobile-burger-icon burger-icon burger-icon-left"
                      variant="link"
                      @click="mobileMenu = !mobileMenu"
                    >
                      <span></span>
                    </b-button>
                  </div>
                  <!--begin::Notifications-->
                  <b-dropdown
                    v-if="false"
                    class="dropdown topbar-item"
                    toggle-class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-white"
                    menu-class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"
                    size="lg"
                    offset="10"
                    right
                    :no-caret="true"
                  >
                    <!--begin::Toggle-->
                    <template slot="button-content">
                      <div
                        class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-white"
                      >
                        <span
                          class="flaticon2-bell-2 svg-icon svg-icon-xl svg-icon-dark-7"
                          style="font-size: 1.5rem"
                        >
                        </span>
                      </div>
                    </template>
                    <!--end::Toggle-->
                    <!--begin::Dropdown-->
                    <template slot="default">
                      <alerts />
                    </template>
                    <!--end::Dropdown-->
                  </b-dropdown>
                  <profiles profile="user"></profiles>
                  <!--begin::Quick Actions-->
                  <b-dropdown
                    id="bots-menu"
                    class="dropdown topbar-item"
                    toggle-class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1"
                    menu-class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg menu-rounded"
                    size="lg"
                    offset="10"
                    right
                    :no-caret="true"
                  >
                    <!--begin::Toggle-->
                    <template
                      slot="button-content"
                      class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1"
                    >
                      <span class="svg-icon svg-icon-xl svg-icon-dark-75">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" x="4" y="4" width="7" height="7" rx="1.5"/><path d="M5.5 13h4a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-4A1.5 1.5 0 015.5 13zm9-9h4A1.5 1.5 0 0120 5.5v4a1.5 1.5 0 01-1.5 1.5h-4A1.5 1.5 0 0113 9.5v-4A1.5 1.5 0 0114.5 4zm0 9h4a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-4a1.5 1.5 0 011.5-1.5z" fill="#000" opacity=".3"/></g></svg>
                        <!--end::Svg Icon-->
                      </span>
                    </template>
                    <!--end::Toggle-->
                    <!--begin::Dropdown-->
                    <template slot="default">
                      <bots />
                    </template>
                    <!--end::Dropdown-->
                  </b-dropdown>
                  <!--end::Quick Actions-->
                  <!--begin::Quick panel-->
                  <!--begin::Language-->
                  <b-dropdown
                    class="dropdown topbar-item"
                    toggle-class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1"
                    menu-class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right"
                    offset="10"
                    right
                    no-caret
                  >
                    <template slot="button-content">
                      <img
                        class="h-20px w-20px rounded-sm"
                        :src="
                          require(`@/assets/images/flags/${actualLanguage}.svg`)
                        "
                        alt=""
                      />
                    </template>
                    <b-dropdown-item
                      v-for="lang in languages"
                      :key="lang"
                      @click="changeLanguage(lang.toLowerCase())"
                    >
                      <span class="symbol symbol-20 mr-3">
                        <img
                          :src="require(`@/assets/images/flags/${lang}.svg`)"
                          alt=""
                        />
                      </span>
                      <span class="navi-text">
                        {{ $t(`generic.languages.${lang}`) }}
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <!--end::Language-->
                  <!--begin::User-->
                  <div class="topbar-item">
                    <div
                      class="btn btn-icon btn-hover-transparent-white w-auto d-flex align-items-center btn-lg px-2"
                      @click="panel = !panel"
                    >
                      <div class="d-flex flex-column text-right pr-3">
                        <span
                          class="text-dark-50 font-weight-bold font-size-sm d-none d-md-inline"
                          >{{ fullname }}</span
                        >
                        <span
                          class="text-dark font-weight-bolder font-size-sm d-none d-md-inline"
                          v-html="archetype"
                        />
                        >
                      </div>
                      <span class="symbol symbol-35">
                        <avatar size="sm" :avatar="avatar" />
                      </span>
                    </div>
                  </div>
                  <!--end::User-->
                </div>
                <!--end::Topbar-->
              </div>
              <!--end::Container-->
            </div>
            <!--end::Top-->
            <!--begin::Bottom-->
            <div class="header-bottom">
              <!--begin::Container-->
              <div class="container">
                <!--begin::Header Menu Wrapper-->
                <div
                  class="header-menu-wrapper header-menu-wrapper-left w-xl-100 "
                  :class="{ 'header-menu-wrapper-on': mobileMenu }"
                  id="kt_header_menu_wrapper"
                >
                  <!--begin::Header Menu-->
                  <div
                    id="kt_header_menu"
                    class="header-menu header-menu-left header-menu-mobile header-menu-layout-default"
                  >
                    <div class="d-lg-none">
                      <div
                        class=" d-lg-none offcanvas-header d-flex align-items-center justify-content-between pt-5 pl-5"
                      >
                        <button
                          @click="mobileMenu = !mobileMenu"
                          target="_self"
                          class="btn btn btn-xs btn-icon btn-light btn-hover-primary btn-secondary"
                        >
                          <i class="ki ki-close icon-xs text-muted"></i>
                        </button>
                      </div>
                    </div>
                    <!--begin::Header Nav-->
                    <ul class="menu-nav align-items-start mt-2">
                      <router-link
                        :to="{ name: item.to }"
                        tag="li"
                        class="menu-item menu-item-submenu menu-item-rel"
                        active-class="menu-item-here"
                        aria-haspopup="true"
                        v-for="item in visibleMenu"
                        :key="item.to"
                      >
                        <div class="menu-link">
                          <span class="menu-text">{{ item.title }}</span>
                          <span class="menu-desc">{{ item.subtitle }}</span>
                          <i class="menu-arrow"></i>
                        </div>
                      </router-link>
                    </ul>
                    <!--end::Header Nav-->
                  </div>
                  <!--end::Header Menu-->
                </div>
                <!--end::Header Menu Wrapper-->
              </div>
              <!--end::Container-->
            </div>
            <!--end::Bottom-->
          </div>
          <!--end::Header-->
        </div>
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__slideInRight"
      leave-active-class="animate__animated animate__slideOutRight"
    >
      <user-panel v-show="panel" @user-panel-closed="panel = false" to="User" />
    </transition>
    <div class="offcanvas-overlay" @click="panel = false" v-if="panel"></div>
    <button
      v-if="actualBot"
      :class="'chat ' + (hasToBounce ? 'bounce' : 'd-none')"
      @click="callBot({ bot: actualBot })"
    >
      <img
        v-if="actualBot && actualBot.image !== undefined"
        class=""
        :src="require(`@/assets/images/menu/bots/${actualBot.image}`)"
      />
    </button>
    <transition enter-active-class="animate__animated animate__bounceInRight">
      <b-modal
        :modal-class="
          'modal modal-sticky modal-sticky-bottom-right chatbot-' + chatSize
        "
        id="chatbot"
        :data-backdrop="false"
        :hide-header="true"
        :hide-footer="true"
        :size="chatSize"
        :hide-header-close="true"
        :no-enforce-focus="true"
        :lazy="true"
        body-class="p-0"
        v-show="showChatbot"
        @hide="hideChat"
      >
        <template v-slot:default>
          <chatbot
            @expand-modal="expandModalSize()"
            :size="chatSize"
            @close="hideChat"
          />
        </template>
      </b-modal>
    </transition>
  </div>
</template>

<script>
import Alerts from "@/components/menu/Alerts.component";
import Bots from "@/components/menu/Bots.component";
import UserPanel from "@/components/menu/UserPanel.component";
import ChatBot from "@/components/menu/ChatBot.component";
import Avatar from "@/components/profile/Avatar.component";
import Profiles from "@/components/menu/Profiles.component";
import { getMimetype } from "@/utils/helpers";

import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "Menu",
  components: {
    Alerts,
    Profiles,
    Bots,
    "user-panel": UserPanel,
    chatbot: ChatBot,
    Avatar
  },
  data() {
    return {
      panel: false,
      chatSize: localStorage.getItem("chatSize") || "md",
      mobilePanel: false,
      mobileMenu: false,
      search: "",
      showAlerts: false,
      showChatbot: false,
      profilesType: localStorage.getItem("profiles").split(",")
    };
  },
  computed: {
    ...mapGetters("User", [
      "fullname",
      "archetype",
      "isUserLoaded",
      "actualBot",
      "avatar",
      "company",
      "interactions"
    ]),
    ...mapGetters("Chatbot", [
      "areHelpersBotsLoaded",
      "isChatbotActive",
      "isLoading",
      "bots",
      "firstTimeShown"
    ]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Auth", ["lang", "isController", "hasModule"]),
    visibleMenu() {
      return _.filter(this.menu, item => {
        return item.service === undefined || this.hasModule([item.service]);
      });
    },
    menu() {
      let menu = [
        {
          title: this.$t("menu.dashboard.title"),
          subtitle: this.$t("menu.dashboard.subtitle"),
          to: "Dashboard"
        },
        {
          title: this.$t("menu.profile.title"),
          subtitle: this.$t("menu.profile.subtitle"),
          to: "Profile"
        },
        {
          title: this.$t("menu.learning.title"),
          subtitle: this.$t("menu.learning.subtitle"),
          to: "Training"
        },
        {
          title: this.$t("menu.breaches.title"),
          subtitle: this.$t("menu.breaches.subtitle"),
          to: "Breaches",
          service: "abs"
        },
        {
          title: this.$t("menu.achievements.title"),
          subtitle: this.$t("menu.achievements.subtitle"),
          to: "Achievements",
          service: "gaming_achievements"
        },
        {
          title: this.$t("menu.assets.title"),
          subtitle: this.$t("menu.assets.subtitle"),
          to: "Assets"
        },
        {
          title: this.$t("menu.faq.title"),
          subtitle: this.$t("menu.faq.subtitle"),
          to: "Faq"
        }
      ];

      return menu;
    },
    languages() {
      return _.intersection(
        Object.keys(this.$i18n.messages),
        JSON.parse(localStorage.getItem("languages"))
      );
    },
    actualLanguage() {
      if (_.indexOf(this.languages, this.lang) > -1) {
        return this.lang;
      } else {
        return "es-es";
      }
    },
    hasToBounce() {
      return this.actualBot && this.$route.name != "Breaches";
    }
  },
  methods: {
    ...mapActions("User", ["loadUser", "reset_bot"]),
    ...mapActions("Chatbot", [
      "setFirstTimeShown",
      "resetStatus",
      "loadHelpersBots",
      "callBot"
    ]),
    ...mapActions("Auth", ["changeLanguage", "profiles"]),
    callGetMimetype(base64) {
      return getMimetype(base64);
    },
    expandModalSize() {
      if (this.chatSize === "md") {
        localStorage.setItem("chatSize", "xl");
        this.chatSize = "xl";
      } else {
        localStorage.setItem("chatSize", "md");
        this.chatSize = "md";
      }
    },
    toggleChatbot(show = true) {
      this.showChatbot = show;
      if (this.showChatbot) this.$root.$emit("bv::show::modal", "chatbot");
      else this.$root.$emit("bv::hide::modal", "chatbot");
    },
    hideChat() {
      this.showChatbot = false;
      this.toggleChatbot(false);
    }
  },
  watch: {
    isConstantsActive: function(value) {
      if (value) {
        this.loadUser();
        setTimeout(() => {
          if (
            !this.showChatbot &&
            !this.firstTimeShown &&
            this.actualBot &&
            this.actualBot.actualBotStatus ==
              this.getConstant("SURVEY_STATUS_AVAILABLE")
          ) {
            this.callBot({ bot: this.actualBot });
            this.setFirstTimeShown();
          }
        }, 4000);
      }
    },
    isChatbotActive(value) {
      if (value === true) this.toggleChatbot(value);
    },
    isLoading(value) {
      if (value === true) this.toggleChatbot(value);
    },
    isUserLoaded(value) {
      if (!value) {
        this.loadUser();
      }
    },
    actualBot(value) {
      if (value) {
        setTimeout(() => {
          if (
            !this.showChatbot &&
            !this.firstTimeShown &&
            value.actualBotStatus == this.getConstant("SURVEY_STATUS_AVAILABLE")
          ) {
            this.callBot({ bot: this.actualBot });
            this.setFirstTimeShown();
          }
        }, 4000);
      }
    },
    $route: function() {
      this.mobilePanel = false;
      this.mobileMenu = false;
      this.panel = false;
    }
  },
  mounted() {
    if (this.isConstantsActive) {
      if (!this.isUserLoaded) {
        this.loadUser();
      }

      if (this.actualBot) {
        setTimeout(() => {
          if (
            !this.showChatbot &&
            !this.firstTimeShown &&
            this.actualBot.actualBotStatus ==
              this.getConstant("SURVEY_STATUS_AVAILABLE")
          ) {
            this.callBot({ bot: this.actualBot });
            this.setFirstTimeShown();
          }
        }, 4000);
      } else {
        this.reset_bot();
      }
    }

    if (!this.areHelpersBotsLoaded) {
      this.loadHelpersBots();
    }
  }
};
</script>

<style lang="scss" scoped>
.svg-icon > svg:focus {
  outline-width: 0;
}
</style>
<style lang="scss">
#chatbot___BV_modal_backdrop_ {
  opacity: 0.4;
}

.company-logo {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
.dropdown-menu.menu-rounded {
  border-radius: 30px;
}
ul.dropdown-menu.dropdown-menu.p-0.m-0.dropdown-menu-anim-up.dropdown-menu-sm.dropdown-menu-right.dropdown-menu-right.show {
  width: max-content;
}
.modal.modal-sticky.modal-sticky-bottom-right.chatbot-xl {
  min-height: 60vh;
  min-width: 50%;
  margin-bottom: 5vh;
  left: 40%;
  .modal-dialog.modal-xl {
    min-width: 100%;
    height: 80vh;
    .modal-content {
      height: 100%;
      .modal-body {
        display: flex;
        width: 100%;
        height: 100%;
      }
      .card {
        min-width: 100%;
        min-height: 100%;
        overflow: hidden;
      }
    }
  }
}
.chat {
  color: rgb(220, 220, 226);
  position: fixed;
  bottom: 7%;
  right: 4%;
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  border: 0;
  border-radius: 100%;
  background-color: transparent !important;
  z-index: 1001;
  img {
    height: 80%;
  }
}
.chat.animated {
  width: 80px;
  height: 80px;
  text-align: center;
  font-size: 10px;
  line-height: 80px;
  -webkit-font-smoothing: antialiased;
  img {
    height: 70%;
    margin-left: auto;
  }
}

.chat.animated:after,
.chat.animated:before {
  content: "";
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center center;
}

.chat.animated:after {
  animation: rotar 2s -0.5s linear infinite;
}
.chat.animated:before {
  animation: rotarIz 2s -0.5s linear infinite;
}
.chat.animated:after {
  box-shadow: inset 0 5px 0 rgba(0, 250, 130, 0.6),
    inset 5px 0 0 rgba(235, 255, 234, 0.6),
    inset 0 -5px rgba(217, 255, 213, 0.6),
    inset -5px 0 0 rgba(176, 255, 171, 0.6);
}

.chat.animated:before {
  box-shadow: inset 0 5px 0 rgba(0, 200, 130, 0.6),
    inset 5px 0 0 rgba(46, 211, 30, 0.6), inset 0 -5px 0 rgba(41, 192, 28, 0.6),
    inset -5px 0 0 rgba(35, 165, 23, 0.6);
}

@keyframes rotar {
  0% {
    transform: rotateZ(0deg) scaleX(1) scaleY(1);
  }
  50% {
    transform: rotateZ(180deg) scaleX(0.82) scaleY(0.95);
  }
  100% {
    transform: rotateZ(360deg) scaleX(1) scaleY(1);
  }
}

@keyframes rotarIz {
  0% {
    transform: rotateZ(0deg) scaleX(1) scaleY(1);
  }
  50% {
    transform: rotateZ(-180deg) scaleX(0.95) scaleY(0.85);
  }
  100% {
    transform: rotateZ(-360deg) scaleX(1) scaleY(1);
  }
}

.bounce {
  animation: bounce 1.5s infinite;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-20px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.burger-icon.mobile-burger-icon span {
  background-color: white;
  &:after,
  &:before {
    background-color: white;
  }
}
</style>
