import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import VueScrollTo from "vue-scrollto";
import _ from "lodash";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  VueScrollTo.scrollTo("body");
  if (!store.getters["Auth/isLoggedIn"]) {
    next();
    return;
  }
  next({ name: "Dashboard" });
};

const ifAuthenticated = (to, from, next) => {
  VueScrollTo.scrollTo("body");
  let profiles = _.words(localStorage.getItem("profiles"), /[^, ]+/g) || [];
  if (
    store.getters["Auth/isLoggedIn"] &&
    _.intersection(to.meta.profiles, profiles)?.length > 0 &&
    store.getters["Auth/hasModule"](to.meta.modules)
  ) {
    next();
    return;
  } else if (_.intersection(to.meta.profiles, profiles)?.length <= 0) {
    if (_.includes(profiles, "USER")) {
      next({ name: "Dashboard" });
      return;
    } else if (_.includes(profiles, "CONTROLLER")) {
      next({ name: "ControllerDashboard" });
      return;
    } else if (_.includes(profiles, "ADMIN")) {
      next({ name: "AdminDashboard" });
      return;
    }
  }
  store.dispatch("Auth/logout");
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/TwoStepLogin"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/user",
    name: "User",
    component: () => import("@/views/User"),
    children: [
      {
        name: "Account",
        path: "/account/:option?",
        component: () => import("@/views/user/Account"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER", "ADMIN"],
          bot: true
        }
      },
      {
        name: "Dashboard",
        path: "dashboard",
        component: () => import("@/views/user/Dashboard"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          bot: true
        }
      },
      {
        name: "Profile",
        path: "profile",
        component: () => import("@/views/user/Profile"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          bot: true
        }
      },
      {
        name: "Achievements",
        path: "achievements",
        component: () => import("@/views/user/Achievements"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          modules: ["gaming_achievements"],
          bot: true
        }
      },
      {
        name: "Awareness",
        path: "profile/awareness",
        component: () => import("@/views/user/Awareness"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          modules: ["awareness"],
          bot: true
        }
      },
      {
        name: "Wellbeing",
        path: "profile/wellbeing",
        component: () => import("@/views/user/Wellbeing"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          modules: ["wellbeing"],
          bot: true
        }
      },
      {
        name: "Archetype",
        path: "profile/archetype",
        component: () => import("@/views/user/Archetype"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          modules: ["archetype"],
          bot: true
        }
      },
      {
        name: "Security",
        path: "profile/security",
        component: () => import("@/views/user/Security"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          modules: ["risk"],
          bot: true
        }
      },
      {
        name: "Impact",
        path: "profile/impact",
        component: () => import("@/views/user/Impact"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          modules: ["impact"],
          bot: true
        }
      },
      {
        name: "SocialEngineering",
        path: "profile/social-engineering",
        component: () => import("@/views/user/SocialEngineering"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          modules: ["socialengineering"],
          bot: true
        }
      },
      {
        name: "Burnout",
        path: "profile/burnout",
        component: () => import("@/views/user/Burnout"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          modules: ["burnout"],
          bot: true
        }
      },
      {
        name: "Training",
        path: "sessions/:typeId?",
        component: () => import("@/views/user/Training"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          bot: true
        }
      },
      {
        name: "TrainingDetail",
        path: "sessions/detail/:id",
        component: () => import("@/views/user/TrainingDetail"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          bot: true
        }
      },
      {
        name: "Faq",
        path: "faq/:section?",
        component: () => import("@/views/user/Faq"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          bot: true
        }
      },
      {
        name: "Assets",
        path: "assets/:status?/:family?/:priority?",
        component: () => import("@/views/user/Assets"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          bot: true
        }
      },
      {
        name: "Cybertest",
        path: "cybertest/:surveyEntityId",
        component: () => import("@/views/user/Cybertest"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          nomenu: true,
          fullscreen: true
        }
      },
      {
        name: "Cybergaming",
        path: "cybergaming/:surveyEntityId",
        component: () => import("@/views/user/Cybergaming"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          nomenu: true,
          fullscreen: true
        }
      },
      {
        name: "CustomBotOneAnswer",
        path: "custom/:surveyEntityId",
        component: () => import("@/views/user/customs/CustomBotOneAnswer"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          nomenu: true,
          fullscreen: true
        }
      },
      {
        name: "Breaches",
        path: "breaches",
        component: () => import("@/views/user/Breaches"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER", "USER"],
          modules: ["abs"]
        }
      }
    ],
    beforeEnter: ifAuthenticated,
    meta: {
      profiles: ["CONTROLLER", "USER"]
    }
  },
  {
    path: "/controller",
    name: "Controller",
    component: () => import("@/views/Controller"),
    children: [
      {
        name: "DefaultLayout",
        path: "companies-management",
        component: () => import("@/views/controller/DefaultLayout"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER"]
        },
        children: [
          {
            name: "ControllerCompaniesManagement",
            path: "",
            component: () =>
              import("@/views/controller/ControllerCompaniesManagement"),
            beforeEnter: ifAuthenticated,
            meta: {
              profiles: ["CONTROLLER"]
            }
          }
        ]
      },
      {
        name: "GeneralDashboard",
        path: "dashboard",
        component: () => import("@/views/controller/Dashboard"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["CONTROLLER"],
          sidebar: true
        },
        children: [
          {
            name: "ControllerDashboard",
            path: "",
            component: () => import("@/views/controller/dashboard/General"),
            beforeEnter: ifAuthenticated,
            meta: {
              profiles: ["CONTROLLER"],
              sidebar: true
            }
          },
          {
            name: "MultiRiskDashboard",
            path: "multirisk",
            component: () => import("@/views/controller/dashboard/MultiRisk"),
            beforeEnter: ifAuthenticated,
            meta: {
              modules: ["multi_risk"],
              profiles: ["CONTROLLER"],
              sidebar: true
            }
          },
          {
            name: "AwarenessDashboard",
            path: "awareness",
            component: () => import("@/views/controller/dashboard/Awareness"),
            beforeEnter: ifAuthenticated,
            meta: {
              modules: ["awareness"],
              profiles: ["CONTROLLER"],
              sidebar: true
            }
          },
          {
            name: "AbsDashboard",
            path: "abs",
            component: () => import("@/views/controller/dashboard/Abs"),
            beforeEnter: ifAuthenticated,
            meta: {
              modules: ["abs"],
              profiles: ["CONTROLLER"],
              sidebar: true
            }
          },
          {
            name: "ReportsDashboard",
            path: "reports",
            component: () => import("@/views/controller/dashboard/Reports"),
            beforeEnter: ifAuthenticated,
            meta: {
              profiles: ["CONTROLLER"],
              sidebar: true
            }
          },
          {
            name: "PhishingDashboard",
            path: "phishing",
            component: () => import("@/views/controller/dashboard/Phishing"),
            beforeEnter: ifAuthenticated,
            meta: {
              modules: ["phishing"],
              profiles: ["CONTROLLER"],
              sidebar: true
            }
          }
        ]
      },
      {
        path: "campaigns",
        component: () => import("@/views/controller/DefaultLayout"),
        meta: {
          modules: ["phishing"]
        },
        children: [
          {
            name: "ListCampaigns",
            path: "",
            component: () => import("@/views/controller/phishing/Campaigns"),
            beforeEnter: ifAuthenticated,
            meta: {
              profiles: ["CONTROLLER"],
              modules: ["phishing"]
            }
          },
          {
            name: "CreateCampaign",
            path: "new",
            component: () =>
              import("@/views/controller/phishing/CreateCampaign"),
            beforeEnter: ifAuthenticated,
            meta: {
              profiles: ["CONTROLLER"],
              modules: ["phishing"]
            }
          },
          {
            name: "ViewCampaign",
            path: ":id",
            component: () => import("@/views/controller/phishing/ViewCampaign"),
            beforeEnter: ifAuthenticated,
            meta: {
              profiles: ["CONTROLLER"],
              modules: ["phishing"]
            }
          }
        ]
      },
      {
        path: "users",
        component: () => import("@/views/controller/DefaultLayout"),
        children: [
          {
            name: "Users",
            path: "",
            component: () => import("@/views/controller/Users"),
            meta: {
              profiles: ["CONTROLLER"]
            }
          },
          {
            name: "UserDetail",
            path: ":id",
            component: () => import("@/views/controller/UserDetail"),
            beforeEnter: ifAuthenticated,
            meta: {
              profiles: ["CONTROLLER"]
            }
          }
        ]
      },
      {
        path: "departments",
        component: () => import("@/views/controller/DefaultLayout"),
        children: [
          {
            name: "Departments",
            path: "",
            component: () => import("@/views/controller/Departments"),
            meta: {
              profiles: ["CONTROLLER"]
            }
          },
          {
            name: "DepartmentDetail",
            path: ":id",
            component: () => import("@/views/controller/DepartmentDetail"),
            beforeEnter: ifAuthenticated,
            meta: {
              profiles: ["CONTROLLER"]
            }
          }
        ]
      },
      {
        path: "organization",
        component: () => import("@/views/controller/DefaultLayout"),
        children: [
          {
            name: "Organization",
            path: "",
            component: () => import("@/views/controller/Organization"),
            meta: {
              profiles: ["CONTROLLER"]
            }
          }
        ]
      },
      {
        path: "action-plan",
        component: () => import("@/views/controller/DefaultLayout"),
        children: [
          {
            name: "ActionPlan",
            path: "",
            component: () => import("@/views/controller/ActionPlan"),
            meta: {
              modules: ["risk"],
              profiles: ["CONTROLLER"]
            }
          }
        ]
      }
    ]
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/Admin"),
    beforeEnter: ifAuthenticated,
    meta: {
      profiles: ["ADMIN"]
    },
    children: [
      {
        name: "AdminDashboard",
        path: "dashboard",
        component: () => import("@/views/admin/Dashboard"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["ADMIN"]
        },
        children: [
          {
            name: "Company",
            path: "detail",
            component: () => import("@/views/admin/Company"),
            beforeEnter: ifAuthenticated,
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "AdminCompaniesManagement",
            path: "companies-management",
            component: () => import("@/views/admin/AdminCompaniesManagement"),
            beforeEnter: ifAuthenticated,
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "CompanyCreate",
            path: "new",
            component: () => import("@/views/admin/companies/CompanyCreate"),
            meta: {
              profiles: ["ADMIN"]
            }
          }
        ]
      },
      {
        name: "Companies",
        path: "companies",
        component: () => import("@/views/admin/DefaultLayout"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["ADMIN"]
        },
        children: [
          {
            name: "CompanyUpdate",
            path: ":id",
            component: () => import("@/views/admin/companies/CompanyUpdate"),
            meta: {
              profiles: ["ADMIN"]
            }
          }
        ]
      },
      {
        name: "Users",
        path: "users",
        component: () => import("@/views/admin/DefaultLayout"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["ADMIN"]
        },
        children: [
          {
            name: "UsersList",
            path: "",
            component: () => import("@/views/admin/users/UsersList"),
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "UsersExcel",
            path: "excel",
            component: () => import("@/views/admin/users/UsersExcel"),
            props: true,
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "UserCreate",
            path: "new",
            component: () => import("@/views/admin/users/UserCreate"),
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "UserUpdate",
            path: ":id?",
            component: () => import("@/views/admin/users/UserUpdate"),
            meta: {
              profiles: ["ADMIN"]
            }
          }
        ]
      },
      {
        name: "Departments",
        path: "departments",
        component: () => import("@/views/admin/DefaultLayout"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["ADMIN"]
        },
        children: [
          {
            name: "DepartmentsList",
            path: "",
            component: () =>
              import("@/views/admin/departments/DepartmentsList"),
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "DepartmentsExcel",
            path: "excel",
            component: () =>
              import("@/views/admin/departments/DepartmentsExcel"),
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "DepartmentUpdate",
            path: ":id?",
            component: () =>
              import("@/views/admin/departments/DepartmentUpdate"),
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "DepartmentCreate",
            path: "new",
            component: () =>
              import("@/views/admin/departments/DepartmentCreate"),
            meta: {
              profiles: ["ADMIN"]
            }
          }
        ]
      },
      {
        name: "Tags",
        path: "tags",
        component: () => import("@/views/admin/DefaultLayout"),
        beforeEnter: ifAuthenticated,
        meta: {
          profiles: ["ADMIN"]
        },
        children: [
          {
            name: "TagsList",
            path: "",
            component: () => import("@/views/admin/tags/TagsList"),
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "TagView",
            path: ":id",
            component: () => import("@/views/admin/tags/TagView"),
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "TagValueCreate",
            path: ":id/value/create",
            component: () => import("@/views/admin/tags/TagValueCreate"),
            meta: {
              profiles: ["ADMIN"]
            }
          },
          {
            name: "TagValueEdit",
            path: ":id/value/edit/:value",
            component: () => import("@/views/admin/tags/TagValueEdit"),
            meta: {
              profiles: ["ADMIN"]
            }
          }
        ]
      }
    ]
  },
  {
    name: "Token",
    path: "/password/:token",
    component: () => import("@/views/Token"),
    beforeEnter: ifNotAuthenticated
  },
  {
    name: "Autologin",
    path: "/token/",
    component: () => import("@/views/Autologin")
  },
  {
    name: "Maintenance",
    path: "/maintenance",
    component: () => import("@/views/Maintenance")
  },
  {
    // will match everything
    path: "/federation/not-found",
    name: "FederationNotFound",
    component: () => import("@/views/FederationNotFound")
  },
  {
    // will match everything
    path: "/company/action-plan",
    name: "CompanyActionPlan",
    beforeEnter: ifAuthenticated,
    meta: {
      profiles: ["CONTROLLER"]
    },
    component: () => import("@/views/controller/ActionPlan")
  },
  {
    // will match everything
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// error handler
const onError = e => {
  // avoid NavigationDuplicated
  if (e.name !== "NavigationDuplicated" && e.name !== "NavigationDuplicated")
    throw e;
};

// keep original function
const _push = router.__proto__.push;
// then override it
router.__proto__.push = function push(...args) {
  try {
    const op = _push.call(this, ...args);
    if (op instanceof Promise) op.catch(onError);
    return op;
  } catch (e) {
    onError(e);
  }
};

export default router;
