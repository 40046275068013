<template>
  <div>
    <change-language />
    <div class="d-flex flex-column flex-root">
      <!--begin::Login-->
      <div
        class="
      login login-2
      d-flex
      flex-column flex-lg-row flex-row-fluid
      bg-white
      fill
    "
      >
        <!--begin::Aside-->
        <div
          class="
        login-aside
        position-relative
        overflow-hidden
        order-2 order-lg-1
        d-flex
        flex-column-fluid flex-lg-row-auto
        py-9
        px-7
        py-lg-13
        px-lg-35
        w-100
        max-w-600px
      "
        >
          <!--begin: Aside Container-->
          <div
            class="d-flex flex-row-fluid flex-column justify-content-between"
          >
            <!--begin::Logo-->
            <a href="#" class="text-center pt-8">
              <b-img-lazy
                src="@/assets/images/login/ky.png"
                class="max-w-70vw"
                alt=""
              />
            </a>
            <!--end::Logo-->
            <!--begin::Aside body-->
            <div class="d-flex flex-column-fluid flex-column flex-center">
              <!--begin::Signin-->
              <transition
                enter-active-class="animate__animated animate__backInUp"
              >
                <div
                  class="login-form login-signin w-100 max-w-450px"
                  v-show="!showForgot && !showForgotTotp"
                >
                  <!--begin::Form-->
                  <b-form
                    @submit.stop.prevent="loginBySteps(user)"
                    class="form mt-9"
                  >
                    <!--begin::Title-->
                    <!--end::Title-->
                    <!--begin::Form group-->
                    <div role="group" class="form-group mb-5">
                      <div class="d-flex justify-content-between mt-n1">
                        <label
                          class="font-size-h6 font-weight-bolder text-dark"
                          for="login"
                        >
                          {{ $t("login.form.login") }}
                        </label>
                      </div>
                      <b-form-input
                        class="
                      form-control form-control-solid
                      h-auto
                      py-5
                      px-6
                      rounded-lg
                      font-size-h6 font-weight-bold
                    "
                        type="email"
                        id="login"
                        :disabled="step !== 'username' || step === ''"
                        v-model="user.login"
                        :state="loginErrorStatus"
                        autocomplete="username"
                      />
                      <b-form-invalid-feedback
                        id="login-feedback"
                        v-if="authStatus !== ''"
                      >
                        {{
                          callGetError(
                            authStatus == "empty"
                              ? `${authStatus}_login`
                              : authStatus
                          )
                        }}
                      </b-form-invalid-feedback>
                    </div>
                    <!--end::Form group-->
                    <!--begin::Form group-->
                    <transition
                      enter-active-class="animate__animated animate__backInLeft"
                    >
                      <div
                        class="form-group"
                        v-if="
                          ((step === 'password' || step === 'totp') &&
                            user.login !== '') ||
                            authStatus === 'conditions'
                        "
                      >
                        <div class="d-flex justify-content-between mt-n1">
                          <label
                            for="password"
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >
                            {{ $t("login.form.password") }}
                          </label>
                        </div>
                        <b-form-input
                          class="
                        form-control form-control-solid
                        h-auto
                        py-5
                        px-6
                        rounded-lg
                        font-size-h6 font-weight-bold
                      "
                          type="password"
                          id="password"
                          :disabled="step !== 'password'"
                          :state="passwordErrorStatus"
                          v-model="user.password"
                          autocomplete="current-password"
                        />
                        <b-form-invalid-feedback
                          id="password-feedback"
                          v-if="authStatus !== ''"
                        >
                          {{
                            callGetError(
                              authStatus == "empty"
                                ? `${authStatus}_password`
                                : authStatus
                            )
                          }}
                        </b-form-invalid-feedback>
                      </div>
                    </transition>
                    <!--begin::Form group-->
                    <transition
                      enter-active-class="animate__animated animate__backInLeft"
                    >
                      <div
                        class="form-group"
                        v-if="
                          (step === 'totp' &&
                            user.login !== '' &&
                            user.password !== '') ||
                            (authStatus === 'conditions' && step === 'totp')
                        "
                      >
                        <div class="d-flex justify-content-between mt-n1">
                          <label
                            for="totp"
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >
                            {{ $t("login.form.totp") }}
                          </label>
                        </div>
                        <v-otp-input
                          ref="otpInput"
                          input-classes="form-control form-control-solid
                            h-auto
                            py-5
                            px-7
                            rounded-lg
                            font-size-h6 font-weight-bold"
                          separator="&nbsp;&nbsp;"
                          :num-inputs="6"
                          :disabled="step !== 'totp'"
                          :should-auto-focus="true"
                          :is-input-num="true"
                          @on-complete="handleOnComplete"
                          :state="totpErrorStatus"
                        />
                        <b-form-invalid-feedback
                          class="d-block"
                          id="totp-feedback"
                          v-if="errorTotp"
                        >
                          {{ callGetError(this.errorCode) }}
                        </b-form-invalid-feedback>
                      </div>
                    </transition>
                    <b-form-group
                      class="fv-plugins-icon-container text-left"
                      v-show="authStatus === 'conditions'"
                    >
                      <b-form-checkbox
                        v-model="user.termsOfUse"
                        :state="user.termsOfUse"
                        class="mb-3 mr-1"
                        size="lg"
                        :required="true"
                        style="display: inline-block"
                      >
                      </b-form-checkbox>
                      <div style="display: inline-block">
                        <a
                          @click="$bvModal.show('modal-terms')"
                          href="javascript:void(0)"
                        >
                          {{ $t("login.conditions.termsOfUse") }}
                        </a>
                      </div>
                      <div class="clearfix" />
                      <b-form-checkbox
                        v-model="user.privacyPolicy"
                        :state="user.privacyPolicy"
                        class="mb-3 mr-1"
                        id="conditions"
                        size="lg"
                        :required="true"
                        style="display: inline-block"
                      >
                      </b-form-checkbox>
                      <div style="display: inline-block">
                        <a
                          @click="$bvModal.show('modal-privacy')"
                          href="javascript:void(0)"
                        >
                          {{ $t("login.conditions.privacyPolicy") }}
                        </a>
                      </div>
                      <b-form-invalid-feedback
                        id="conditions-feedback"
                        :state="validateConditions"
                      >
                        {{ callGetError(authStatus) }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!--end::Form group-->
                    <div
                      class="d-flex justify-content-end mb-2 mt-1"
                      v-if="step !== 'totp'"
                    >
                      <a
                        href="javascript:;"
                        class="
                      text-primary
                      font-size-md font-weight-bolder
                      text-hover-primary
                    "
                        @click="showForgot = true"
                      >
                        {{ $t("login.buttons.forgot") }}
                      </a>
                    </div>
                    <div class="d-flex justify-content-end mb-2 mt-1" v-else>
                      <a
                        href="javascript:;"
                        class="
                      text-primary
                      font-size-md font-weight-bolder
                      text-hover-primary
                    "
                        @click="showForgotTotp = true"
                      >
                        {{ $t("login.button.forgot.totp") }}
                      </a>
                    </div>
                    <!--begin::Action-->
                    <div class="text-center mt-1">
                      <b-button
                        id="next-signin"
                        type="submit"
                        pill
                        variant="primary"
                        class="btn font-weight-bolder font-size-h6 px-8 py-4 my-4"
                        v-if="step === 'username' || step === ''"
                      >
                        {{ $t("login.buttons.next") }}
                      </b-button>
                      <b-button
                        @click="cancel"
                        pill
                        variant="light-primary"
                        class="btn font-weight-bolder font-size-h6 px-8 py-4 my-7"
                        v-if="
                          step === 'password' ||
                            step === 'totp' ||
                            authStatus === 'conditions'
                        "
                      >
                        {{ $t("login.buttons.cancel") }}
                      </b-button>
                      <b-button
                        id="signin"
                        type="submit"
                        pill
                        variant="primary"
                        class="
                          btn
                          font-weight-bolder font-size-h6
                          px-8
                          py-4
                          my-7
                          ml-4
                        "
                        :disabled="
                          (authStatus === 'conditions' &&
                            (!user.privacyPolicy || !user.termsOfUse)) ||
                            (step === 'totp' && user.totp === '')
                        "
                        v-if="
                          step === 'password' ||
                            step === 'totp' ||
                            authStatus === 'conditions'
                        "
                      >
                        {{ $t("login.buttons.signin") }}
                      </b-button>
                    </div>
                    <!--end::Action-->
                  </b-form>
                  <!--end::Form-->
                </div>
              </transition>
              <!--end::Signin-->

              <!--begin::Forgot-->
              <transition
                enter-active-class="animate__animated animate__backInUp"
              >
                <div
                  class="login-form login-forgot pt-5 w-100 max-w-450px"
                  v-show="showForgot"
                >
                  <!--begin::Form-->
                  <b-form
                    class="form"
                    novalidate="novalidate"
                    @submit.stop.prevent="remember(rememberModel.email)"
                  >
                    <!--begin::Title-->
                    <div class="text-center pb-8">
                      <h3 class="font-weight-bolder text-dark display5">
                        {{ $t("login.forgotten") }}
                      </h3>
                      <p class="text-muted font-weight-bold font-size-h4">
                        {{ $t("login.enterEmail") }}
                      </p>
                    </div>
                    <!--end::Title-->
                    <!--begin::Form group-->
                    <div role="group" class="form-group">
                      <b-form-input
                        class="
                      form-control form-control-solid
                      h-auto
                      py-5
                      px-6
                      rounded-lg
                      font-size-h6 font-weight-bold
                    "
                        type="email"
                        :placeholder="$tc('login.enterUserName')"
                        v-model="rememberModel.email"
                        :state="rememberErrorStatus"
                        autocomplete="off"
                        id="remember"
                      />
                      <b-form-invalid-feedback
                        id="remember-feedback"
                        v-if="authStatus !== ''"
                      >
                        {{ callGetError(authStatus) }}
                      </b-form-invalid-feedback>
                    </div>
                    <!--end::Form group-->
                    <!--begin::Form group-->
                    <div
                      class="form-group d-flex flex-wrap flex-center pb-lg-0 pb-3"
                    >
                      <b-button
                        type="submit"
                        variant="primary"
                        pill
                        class="font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4"
                      >
                        {{ $t("login.buttons.remember") }}
                      </b-button>
                      <b-button
                        type="button"
                        variant="light-primary"
                        pill
                        @click="showForgot = false"
                        class="font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4"
                      >
                        {{ $t("login.buttons.cancel") }}
                      </b-button>
                    </div>
                    <!--end::Form group-->
                  </b-form>
                  <!--end::Form-->
                </div>
              </transition>
              <!--end::Forgot-->
              <!--begin::ForgotTotp-->
              <transition
                enter-active-class="animate__animated animate__backInUp"
              >
                <div
                  class="login-form login-forgot pt-5 w-100 max-w-450px"
                  v-show="showForgotTotp"
                >
                  <!--begin::Title-->
                  <div class="text-center pb-8">
                    <h3
                      class="font-weight-bolder text-dark display5"
                      v-html="$t('login.totp.title')"
                    />
                    <p
                      class="text-muted font-weight-bold font-size-h4"
                      v-html="$t('login.totp.description')"
                    />
                  </div>
                  <!--end::Title-->
                  <div
                    class="form-group d-flex flex-wrap flex-center pb-lg-0 pb-3"
                  >
                    <b-button
                      variant="primary"
                      @click="configureTotp"
                      pill
                      class="font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4"
                    >
                      {{ $t("login.button.totp") }}
                    </b-button>
                    <b-button
                      type="button"
                      variant="light-primary"
                      pill
                      @click="showForgotTotp = false"
                      class="font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4"
                    >
                      {{ $t("login.buttons.cancel") }}
                    </b-button>
                  </div>
                  <!--end::Form group-->
                </div>
              </transition>
              <!--end::Forgot-->
            </div>
            <div class="d-flex flex-column-fluid flex-column">
              <div style="display: inline-block" class="mt-auto">
                <a
                  @click="$bvModal.show('modal-privacy')"
                  href="javascript:void(0)"
                >
                  {{ $t("footer.privacy") }}
                </a>
                |
                <a
                  @click="$bvModal.show('modal-terms')"
                  href="javascript:void(0)"
                >
                  {{ $t("footer.termsOfUse") }}
                </a>
              </div>
            </div>
            <!--end::Aside body-->
          </div>
          <!--end: Aside Container-->
        </div>

        <!--begin::Aside-->
        <!--begin::Content-->
        <div
          class="
        content
        order-1 order-lg-2
        flex-column-row-auto flex-grow-1
        pt-md-0
        pb-40
        bgi-no-repeat bgi-position-y-bottom bgi-position-x-center
        login-right
      "
        >
          <!--begin::Content body-->
          <div class="d-flex flex-center pb-lg-40 pt-lg-0 pt-md-0 pb-40">
            <div
              class="
            d-flex
            flex-column
            justify-content-center
            text-center
            pt-xl-30 pt-lg-20
            pb-sm-10
          "
            >
              <!--begin: Aside Container-->
              <div
                class="d-flex flex-row-fluid flex-column justify-content-between"
              ></div>

              <!--begin::Aside-->
              <!--begin::Content-->
              <h1
                class="display4 font-weight-bolder my-7 text-dark"
                style="font-size: 4rem !important"
              >
                <span
                  v-for="(value, index) in $t('login.header')"
                  v-bind:key="index"
                  >{{ value }}<br
                /></span>
              </h1>
              <p
                class="font-weight-bolder font-size-h4 font-size-lg text-dark opacity-70 pb-lg-3 pb-sm-40 pb-10"
                style="font-size: 2rem !important;font-weight: lighter !important"
              >
                <span
                  v-for="(value, index) in $t('login.subheader')"
                  v-bind:key="index"
                  >{{ value }}<br
                /></span>
              </p>
              <b-modal
                id="modal-privacy"
                hide-footer
                size="xl"
                scrollable
                :title="$t('legal.privacy.title')"
              >
                <div
                  class="d-block mb-8 pt-1 pb-4 pr-8 pl-8"
                  v-for="(content, index) in $t('legal.privacy.contents')"
                  :key="content.title"
                >
                  <h3>{{ index + 1 }}- {{ content.title }}</h3>
                  <p v-html="content.content"></p>
                  <span
                    v-for="(child, subindex) in content.children"
                    v-bind:key="child.title"
                  >
                    <h6>
                      {{ index + 1 }}.{{ subindex + 1 }} -
                      <span v-html="child.title" />
                    </h6>
                    <span v-html="child.content" />
                  </span>
                </div>
              </b-modal>
              <b-modal
                id="modal-terms"
                hide-footer
                size="xl"
                scrollable
                :title="$t('legal.terms.title')"
              >
                <div
                  class="d-block mb-8 pt-1 pb-4 pr-8 pl-8"
                  v-for="(content, index) in $t('legal.terms.contents')"
                  :key="content.title"
                >
                  <h3>{{ index + 1 }}- {{ content.title }}</h3>
                  <p v-html="content.content"></p>
                  <span
                    v-for="(child, subindex) in content.children"
                    v-bind:key="child.title"
                  >
                    <h6>
                      {{ index + 1 }}.{{ subindex + 1 }} -
                      <span v-html="child.title" />
                    </h6>
                    <span v-html="child.content" />
                  </span>
                </div>
              </b-modal>
              <!--end::Content-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { info, renewtotp } from "@/api/auth.api";
import { mapActions, mapGetters } from "vuex";
import ChangeLanguage from "@/components/ChangeLanguage.component.vue";
import OtpInput from "@bachdgvn/vue-otp-input";

import { getError } from "@/utils/helpers";

export default {
  name: "TwoLogin",
  components: {
    "change-language": ChangeLanguage,
    "v-otp-input": OtpInput
  },
  data() {
    return {
      user: {
        login: "",
        password: "",
        totp: "",
        termsOfUse: false,
        privacyPolicy: false
      },
      rememberModel: {
        email: ""
      },
      showForgot: _.trimStart(this.$route.hash, "#") === "remember",
      showForgotTotp: false
    };
  },
  computed: {
    ...mapGetters("Auth", [
      "authStatus",
      "errorCode",
      "step",
      "defaultView",
      "profiles",
      "errorTotp"
    ]),
    validateConditions() {
      return this.user.privacyPolicy && this.user.termsOfUse;
    },
    loginErrorStatus() {
      if (
        !this.showForgot &&
        this.authStatus === "empty" &&
        !this.errorTotp &&
        this.user.login === ""
      )
        return false;
      else if (
        !this.showForgot &&
        this.authStatus === "error" &&
        !this.errorTotp &&
        this.step === "username"
      )
        return false;
      else return null;
    },
    passwordErrorStatus() {
      if (
        !this.showForgot &&
        !this.errorTotp &&
        (this.authStatus === "error" ||
          this.authStatus === "blocked" ||
          (this.authStatus === "empty" && this.user.password === ""))
      )
        return false;
      else return null;
    },
    totpErrorStatus() {
      if (!this.showForgot && this.authStatus === "error" && this.errorTotp)
        return true;
      else return null;
    },
    rememberErrorStatus() {
      if (
        (this.showForgot &&
          this.authStatus === "empty" &&
          this.rememberModel.email === "") ||
        this.authStatus === "invalid_email"
      ) {
        return false;
      } else return null;
    }
  },
  methods: {
    configureTotp() {
      renewtotp({
        login: this.user.login,
        password: Buffer.from(this.user.password).toString("base64")
      })
        .then(() => {
          this.$swal(this.$t("errors.remember_sent"));
          this.showForgotTotp = false;
        })
        .catch(() => {
          this.$swal(this.$t("errors.default"));
        });
    },
    handleOnComplete(value) {
      this.user.totp = value;
    },
    callGetError(code, params) {
      return getError(code, params);
    },
    ...mapActions("Auth", [
      "login",
      "reset",
      "resetPassword",
      "remember",
      "loginBySteps",
      "resetSteps",
      "isLoggedIn"
    ]),
    cancel() {
      this.resetSteps();
      this.reset();
      this.user.login = "";
      this.user.password = "";
    }
  },
  mounted() {
    if (!this.isLoggedIn) {
      info({ subdomain: location.hostname }).then(resp => {
        if (resp.data.records.federated) {
          window.location.replace(resp.data.records.url);
        }
      });
    }
  },
  watch: {
    authStatus: function(value) {
      if (value === "error") {
        if (this.step !== "totp") {
          this.user.password = "";
        } else {
          this.user.totp = "";
          this.$swal(this.callGetError(this.errorCode), "", "error");
        }
      } else if (value === "success") {
        if (_.includes(this.profiles, "USER")) {
          this.$router.push({ name: "Dashboard" });
        } else if (_.includes(this.profiles, "CONTROLLER")) {
          this.$router.push({ name: "ControllerDashboard" });
        } else if (_.includes(this.profiles, "ADMIN")) {
          this.$router.push({ name: "AdminDashboard" });
        }
      } else if (value === "remember_sent") {
        this.$swal(
          this.callGetError("success"),
          this.callGetError(this.errorCode)
        );
        this.rememberModel.email = "";
        this.reset();
      }
    },
    rememberModel: {
      deep: true,
      handler(value) {
        if (value.email !== "" && this.authStatus === "invalid_email") {
          this.reset();
        }
      }
    },
    showForgot: function() {
      this.resetSteps();
      this.rememberModel.email = this.user.login;
      this.user.login = "";
      this.user.password = "";
      this.remember.email = "";
      this.reset();
    }
  }
};
</script>

<style src="@/assets/scss/login.scss" lang="scss" scoped></style>

<style lang="scss">
.max-w-70vw {
  max-width: 70%;
}
</style>
