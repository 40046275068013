<template>
  <div id="create-campaign">
    <!--begin::header-->
    <div
      class="d-flex justify-content-between subheader py-2 py-lg-4 subheader-transparent"
      id="kt_subheader"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            {{
              $t("generic.crud.create", {
                module: $t("campaigns.form.title").toLowerCase()
              })
            }}
          </h5>
          <!--end::Page Title-->
        </div>
        <!--end::Page Heading-->
      </div>
      <div class="d-flex align-items-center">
        <!--begin::back button-->
        <b-button
          tag="a"
          @click="$router.push({ name: 'ListCampaigns' })"
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder text-uppercase mr-2"
        >
          <div class="d-flex align-items-center">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
        <!--end::back button-->
      </div>
      <!--end::Info-->
    </div>
    <!--end::header-->
    <!--begin::create campaign form-->
    <transition-group
      enter-active-class="animate__animated animate__slideInLeft"
    >
      <b-card
        class="card-custom text-left gutter-b mt-6"
        v-for="(group, index) in fields"
        :key="group.name"
        v-show="actualStep >= group.step"
        no-body
      >
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <h1>
            {{ index + 1 }}.
            {{ $t(`campaigns.form.groups.${group.name}`) }}
          </h1>
          <b-icon-check-circle-fill
            v-if="actualStep > index + 1 || form.campaignTypeId != null"
            variant="primary"
            scale="2"
          ></b-icon-check-circle-fill>
        </div>
        <div class="card-body">
          <create-campaign-form
            v-if="allLevels && levels"
            :actualStep="actualStep"
            :mustShowField="mustShowField"
            :group="group"
            :form="form"
            :fields="fields"
            :save="save"
            :minDate="form.startDate_date"
            :error="error"
            :errors="errors"
            :levels="allLevels"
            :categories="categories"
            :isSubmitting="isSubmitting"
            @updateActualStep="actualStep = $event"
            @updateLandingTemplate="landingTemplate = $event"
            @updateMailTemplate="mailTemplate = $event"
            @updatePostLandingTemplate="postLandingTemplate = $event"
          ></create-campaign-form>
        </div>
      </b-card>
    </transition-group>
    <!--end::create campaign form-->
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import moment from "moment-timezone";
import { mapGetters, mapActions } from "vuex";

import {
  getDomains,
  getLandings,
  getAttachments,
  getHeaders
} from "@/api/controller/campaigns.api";
import { getTags } from "@/api/controller/stakeholders.api";

import CreateCampaignForm from "@/components/campaign/CreateCampaignForm.component";
import { BIconCheckCircleFill } from "bootstrap-vue";

import { getError } from "@/utils/helpers";
import {
  validate,
  validateFormatDateYYYYMMDD,
  validateFormatTimeHHMMSS
} from "@/utils/validators";

export default {
  name: "CreateCampaign",
  data() {
    return {
      landingTemplate: "",
      mailTemplate: "",
      postLandingTemplate: "",
      sendNow: true,
      errors: [],
      oldForm: {},
      template: {},
      domains: [],
      landings: [],
      attachments: [],
      headers: [],
      companyLandings: [],
      companyAttachments: [],
      companyHeaders: [],
      tags: [],
      scheduling: "manual",
      actualStep: 1,
      modules: ["neurogaitas", "smishing", "smishgaitas"],
      isSubmitting: false,
      form: {
        levels: [],
        categories: [],
        name: "",
        departments: [],
        campaignTypeId: null,
        campaignKind: true,
        campaignName: "",
        subdomain: "",
        sender: "",
        headers: [],
        timezone: {
          text: moment.tz.guess(),
          value: moment.tz.guess()
        },
        category: false,
        level: false,
        startDate_date: moment().format("YYYY-MM-DD"),
        startDate_time: moment().format("HH:mm:ss"),
        endDate_date: "",
        endDate_time: "",
        sendByDate_date: "",
        sendByDate_time: "",
        schedule_type: "",
        template_type: "",
        domain: "",
        landing: null,
        attachment: null,
        totalCampaigns: 0,
        frecuency: false,
        surveyTypeId: false,
        tagKeys: [],
        tagValues: [],
        stakeholderSelection: true,
        subject: ""
      },
      showAttachment: false
    };
  },
  components: { CreateCampaignForm, BIconCheckCircleFill },
  computed: {
    ...mapGetters("Phishing", [
      "isCampaignCreated",
      "error",
      "types",
      "departments"
    ]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Auth", ["hasModule"]),
    scheduleTypeOptions() {
      return [
        {
          text: this.$t("campaigns.form.buttons.sendNow"),
          value: "now"
        },
        {
          text: this.$t("campaigns.form.buttons.programSend"),
          value: "program"
        },
        {
          text: this.$t("campaigns.form.buttons.automaticSend"),
          value: "automatic"
        }
      ];
    },
    typesBySurveyTypeId() {
      const result = _.filter(
        this.types,
        type => parseInt(type.surveyTypeId) === parseInt(this.form.surveyTypeId)
      );
      return result;
    },
    timezones() {
      let result = [];
      _.each(moment.tz.names(), name => {
        result.push({
          text: name,
          value: name
        });
      });
      return result;
    },
    categories() {
      let result = [];
      _.each(
        _.groupBy(
          this.typesBySurveyTypeId,
          survey => survey.campaignCategoryId
        ),
        survey => {
          let unique = _.first(survey);
          if (
            parseInt(unique.surveyTypeId) ===
              parseInt(this.form.surveyTypeId) &&
            (this.form.schedule_type !== "automatic" ||
              parseInt(unique.campaignCategoryId) !== 5) //TODO: To be removed
          ) {
            result.push({
              text: unique.campaignCategory,
              value: unique.campaignCategoryId
            });
          }
        }
      );
      return _.orderBy(result, element => element.text);
    },
    allLevels() {
      const result = [];
      _.each(
        _.groupBy(this.typesBySurveyTypeId, survey => survey.campaignLevelId),
        survey => {
          let unique = _.first(survey);
          result.push({
            text: unique.campaignLevel,
            value: unique.campaignLevelId
          });
        }
      );
      return result;
    },
    levels() {
      const result = [];
      if (
        this.form.schedule_type !== "automatic" &&
        this.form.template_type !== "neurophishing"
      ) {
        _.each(
          _.groupBy(
            _.filter(
              this.typesBySurveyTypeId,
              type =>
                this.form.category &&
                type.campaignCategoryId === this.form.category.value &&
                parseInt(type.surveyTypeId) === parseInt(this.form.surveyTypeId)
            ),
            survey => survey.campaignLevel
          ),
          survey => {
            let unique = _.first(survey);
            result.push({
              text: unique.campaignLevel,
              value: unique.campaignLevelId
            });
          }
        );
      } else {
        _.each(
          _.groupBy(this.typesBySurveyTypeId, survey => survey.campaignLevelId),
          survey => {
            let unique = _.first(survey);
            result.push({
              text: unique.campaignLevel,
              value: unique.campaignLevelId
            });
          }
        );
      }
      return result;
    },
    formatedDepartments() {
      let result = [];
      _.each(this.departments, department => {
        result.push({
          text: `${department.name} (${department.peopleCount})`,
          value: department.stakeholderId
        });
      });
      return result;
    },
    tagKeys() {
      return _.map(this.tags, e => {
        return { value: e.id, text: e.name };
      });
    },
    tagValues() {
      let result = [];
      let selectedKeys = _.filter(this.tags, el =>
        _.includes(
          _.map(this.form.tagKeys, element => element.value),
          el.id
        )
      );

      _.each(selectedKeys, selected => {
        let selectedValues = _.map(selected.values, value => {
          return {
            value: value.value,
            text: value.value + " (" + _.size(value.people) + ")",
            key: selected.id
          };
        });
        result = _.concat(result, selectedValues);
      });
      return result;
    },
    fields() {
      let result = [
        {
          name: "generic",
          step: 1,
          fields: [
            {
              name: "name",
              required: true,
              type: "text",
              maxLength: 100
            },
            {
              name: "stakeholderSelection",
              type: "boolean",
              required: false,
              checked: true,
              width: 120,
              color: {
                checked: "#0BB784",
                unchecked: "#82C7EB"
              },
              options: {
                checked: this.$t(
                  `campaigns.form.stakeholderSelection.options.departments`
                ),
                unchecked: this.$t(
                  `campaigns.form.stakeholderSelection.options.tags`
                )
              }
            },
            {
              name: "departments",
              required: true,
              type: "select",
              options: this.formatedDepartments || [],
              multiple: true,
              stakeholderSelection: true
            },
            {
              name: "tagKeys",
              required: true,
              type: "select",
              options: this.tagKeys || [],
              multiple: true,
              stakeholderSelection: false
            },
            {
              name: "tagValues",
              required: true,
              type: "select",
              options: this.tagValues || [],
              multiple: true,
              stakeholderSelection: false
            },
            {
              name: "campaignKind",
              type: "boolean",
              required: false,
              checked: true,
              width: 67,
              options: {
                checked: this.$t(`campaigns.form.campaignKind.options.normal`),
                unchecked: this.$t(`campaigns.form.campaignKind.options.test`)
              }
            }
          ]
        },
        {
          name: "schedule",
          step: 2,
          fields: [
            {
              name: "schedule_type",
              required: true,
              type: "radio",
              options: this.scheduleTypeOptions
            },
            {
              name: "timezone",
              required: true,
              type: "select",
              options: this.timezones || [],
              schedule_type: ["program"]
            },
            {
              name: "startDate",
              required: true,
              type: "datetime",
              validation:
                !_.isEmpty(this.form.startDate_date) &&
                validateFormatDateYYYYMMDD(this.form.startDate_date) &&
                validateFormatTimeHHMMSS(this.form.startDate_time),
              schedule_type: ["program", "automatic"]
            },
            {
              name: "sendByDate",
              type: "datetime",
              required: true,
              validation:
                !_.isEmpty(this.form.sendByDate_date) &&
                validateFormatDateYYYYMMDD(this.form.sendByDate_date) &&
                validateFormatTimeHHMMSS(this.form.sendByDate_time),
              schedule_type: ["program"]
            },
            {
              name: "endDate",
              type: "datetime",
              required: true,
              validation:
                !_.isEmpty(this.form.endDate_date) &&
                validateFormatDateYYYYMMDD(this.form.endDate_date) &&
                validateFormatTimeHHMMSS(this.form.endDate_time),
              schedule_type: ["program"]
            },
            {
              name: "totalCampaigns",
              required: true,
              type: "number",
              min: 1,
              schedule_type: ["automatic"]
            },
            {
              name: "frecuency",
              required: true,
              type: "slider",
              schedule_type: ["automatic"]
            }
          ]
        },
        {
          name: "template",
          step: 3,
          sendButton: true,
          fields: [
            {
              name: "template_type",
              required: true,
              type: "radio",
              options: [
                {
                  text: this.$t("campaigns.form.buttons.phishing"),
                  value: "phishing"
                }
              ]
            },
            {
              name: "templates_list",
              required: false,
              type: "table",
              options: this.typesBySurveyTypeId || [],
              schedule_type: ["now", "program"],
              template_type: [
                "phishing",
                "neurogaitas",
                "smishing",
                "smishgaitas"
              ]
            },
            {
              name: "levels",
              required: true,
              type: "select",
              schedule_type: ["automatic"],
              template_type: ["phishing"],
              options: this.levels,
              multiple: true
            },
            {
              name: "categories",
              required: true,
              type: "select",
              schedule_type: ["automatic"],
              template_type: ["phishing"],
              options: this.categories,
              multiple: true
            },
            {
              name: "campaignName",
              required: false,
              type: "template_info",
              readonly: true,
              schedule_type: ["now", "program"],
              template_type: [
                "phishing",
                "smishing",
                "neurogaitas",
                "smishgaitas"
              ]
            },
            {
              name: "domain",
              required: true,
              type: "select",
              options: this.domains || [],
              schedule_type: ["now", "program", "automatic"],
              template_type: [
                "phishing",
                "neurogaitas",
                "smishing",
                "smishgaitas"
              ]
            },
            {
              name: "subdomain",
              required: false,
              readonly: true,
              type: "template_info",
              schedule_type: ["now", "program"],
              template_type: [
                "phishing",
                "neurogaitas",
                "smishing",
                "smishgaitas"
              ],
              formatter: value =>
                this.form?.domain ? `${value}.${this.form.domain.value}` : ""
            },
            {
              name: "landing",
              required: true,
              type: "select",
              preview: true,
              options: this.landings || [],
              schedule_type: ["now", "program", "automatic"],
              template_type: [
                "phishing",
                "neurogaitas",
                "smishing",
                "smishgaitas"
              ]
            },
            {
              name: "attachment",
              required: this.showAttachment,
              type: "select",
              show: this.showAttachment,
              options: this.attachments || [],
              schedule_type: ["now", "program", "automatic"],
              template_type: ["phishing", "neurogaitas", "smishgaitas"]
            },
            {
              name: "sender",
              required: false,
              readonly: true,
              type: "template_info",
              schedule_type: ["now", "program"],
              template_type: [
                "phishing",
                "neurogaitas",
                "smishing",
                "smishgaitas"
              ],
              formatter: value =>
                this.form?.domain ? `${value}@${this.form.domain.value}>` : ""
            },
            {
              name: "headers",
              required: false,
              readonly: true,
              type: "template_info",
              schedule_type: ["now", "program"],
              template_type: [
                "phishing",
                "neurogaitas",
                "smishing",
                "smishgaitas"
              ],
              formatter: value => {
                let result = "";
                _.each(
                  value,
                  header => (result += `${header.key}: ${header.value} </br>`)
                );
                return result;
              },
              extraParams: this.companyHeaders
            },
            {
              name: "subject",
              required: false,
              readonly: true,
              type: "template_info",
              schedule_type: ["now", "program"],
              template_type: [
                "phishing",
                "neurogaitas",
                "smishing",
                "smishgaitas"
              ]
            }
          ]
        }
      ];

      _.each(this.modules, module => {
        if (this.hasModule([module])) {
          const indexOfTemplate = _.findIndex(
            result,
            element => element.name === "template"
          );
          const indexOfType = _.findIndex(
            result[indexOfTemplate].fields,
            element => element.name === "template_type"
          );
          result[indexOfTemplate].fields[indexOfType].options.push({
            text: this.$t(`campaigns.form.buttons.${module}`),
            value: module
          });
        }
      });
      return result;
    }
  },
  methods: {
    ...mapActions("Phishing", [
      "loadPhishing",
      "insertCampaign",
      "insertScheduledCampaign"
    ]),
    areEmptyDepartments(selected) {
      let total = 0;
      let result = this.departments.filter(department =>
        selected.some(element => {
          return department.stakeholderId == element.value;
        })
      );

      result.forEach(department => (total += department.peopleCount));

      return total;
    },
    mustShowField(field) {
      return (
        (field.schedule_type === undefined ||
          _.indexOf(field.schedule_type, this.form.schedule_type) >= 0) &&
        (field.template_type === undefined ||
          _.indexOf(field.template_type, this.form.template_type) >= 0) &&
        (field.stakeholderSelection === undefined ||
          field.stakeholderSelection === this.form.stakeholderSelection) &&
        (field.show === undefined || field.show !== false)
      );
    },
    callGetError(code, params) {
      return getError(code, params);
    },
    save(form) {
      this.isSubmitting = true;
      if (
        this.form.stakeholderSelection &&
        this.areEmptyDepartments(form.departments) == 0
      ) {
        this.$swal({
          html: this.$t("errors.empty_departments"),
          icon: "warning",
          confirmButtonColor: "#0BB783",
          confirmButtonText: this.$t("admin.popup.aceptButton")
        });
        this.isSubmitting = false;
      } else {
        this.errors = [];
        let visibleFields = _.each(
          this.fields,
          group =>
            (group.fields = _.filter(group.fields, field =>
              this.mustShowField(field)
            ))
        );
        let validation = validate(visibleFields, form);
        let startDate = form.startDate_date + " " + form.startDate_time;
        let sendByDate = form.sendByDate_date + " " + form.sendByDate_time;
        let endDate = form.endDate_date + " " + form.endDate_time;
        let startDateMoment = moment(startDate);
        let sendByDateMoment = moment(sendByDate);
        let endDateMoment = moment(endDate);
        if (validation.valid) {
          if (startDateMoment.isValid()) {
            if (
              !_.isEmpty(sendByDate) &&
              sendByDateMoment.isSameOrBefore(startDateMoment)
            ) {
              this.errors.push({
                name: "sendByDate",
                error: "is_after_by_start",
                checker: "startDate"
              });
            }
            if (!_.isEmpty(endDate)) {
              if (!_.isEmpty(sendByDate)) {
                if (endDateMoment.isSameOrBefore(startDateMoment)) {
                  this.errors.push({
                    name: "endDate",
                    error: "is_after_end_start",
                    checker: "startDate"
                  });
                } else {
                  if (endDateMoment.isSameOrBefore(sendByDateMoment)) {
                    this.errors.push({
                      name: "endDate",
                      error: "is_after_end_by",
                      checker: "sendByDate"
                    });
                  }
                }
              }
            }
          } else {
            this.errors.push({
              name: "startDate"
            });
          }
          if (_.isEmpty(this.errors)) {
            this.$swal
              .mixin({
                confirmButtonText: this.$t("campaigns.form.confirm"),
                cancelButtonText: this.$t("campaigns.form.cancel"),
                showCancelButton: true,
                progressSteps: ["1", "2", "3"]
              })
              .queue([
                this.$t("campaigns.form.steps.first"),
                this.$t("campaigns.form.steps.second"),
                this.$t("campaigns.form.steps.third")
              ])
              .then(result => {
                if (result.value) {
                  this.$swal
                    .fire({
                      html: this.$t("campaigns.form.allDone", {
                        type: this.form.campaignKind == true ? "NORMAL" : "TEST"
                      }),
                      showCancelButton: true,
                      cancelButtonText: this.$t("campaigns.form.cancel"),
                      confirmButtonText: this.$t("campaigns.form.launch")
                    })
                    .then(result => {
                      const formattedForm = {
                        ...form,
                        campaignKind:
                          form.campaignKind == true ? "NORMAL" : "TEST"
                      };

                      if (result.isConfirmed) {
                        if (form.schedule_type !== "automatic") {
                          this.insertCampaign(formattedForm);
                        } else {
                          form.category = this.categories;
                          this.insertScheduledCampaign(formattedForm);
                        }
                      }
                    });
                }
              });
          }
        } else {
          this.errors = validation.errors;
        }
        this.isSubmitting = false;
      }
    }
  },
  mounted() {
    const stakeholderId = localStorage.getItem("managedCompanyId");

    if (this.isConstantsActive && !this.isLoading) this.loadPhishing();
    axios
      .all([
        getDomains(stakeholderId),
        getTags(stakeholderId, { allstatus: 0, allStakeholders: 0 }),
        getLandings(stakeholderId),
        getAttachments(stakeholderId),
        getHeaders(stakeholderId)
      ])
      .then(
        axios.spread(
          (
            domainsResp,
            tagsResp,
            landingsResp,
            attachmentResp,
            headersResp
          ) => {
            this.tags = tagsResp.data.records;
            _.each(domainsResp.data.records, domain => {
              this.domains.push({
                text: domain,
                value: domain
              });
            });
            _.each(
              _.orderBy(
                landingsResp.data.records,
                el => el.stakeholderCompanyId,
                "desc"
              ),
              landing => {
                this.companyLandings.push({
                  text: landing.name,
                  value: landing,
                  object: landing,
                  url: landing.url
                });
              }
            );
            _.each(attachmentResp.data.records, attachment => {
              this.companyAttachments.push({
                text: attachment.name,
                value: attachment
              });
            });
            this.companyHeaders = headersResp.data.records;
            this.landings = this.companyLandings;
            this.attachments = this.companyAttachments;
          }
        )
      );

    this.$root.$on(
      "updateLandingTemplate",
      template => (this.landingTemplate = template)
    );
    this.$root.$on(
      "updateAllowAttachment",
      template => (this.showAttachment = template === 1)
    );
    this.$root.$on(
      "updateMailTemplate",
      template => (this.mailTemplate = template)
    );
    this.$root.$on("updatePostLandingTemplate", template => {
      if (template !== "") {
        let defaultObjLanding = {
          text: this.$t("campaigns.form.groups.template"),
          value: template,
          url: template,
          object: {
            type: "template"
          }
        };
        let defaultObjAttachment = {
          text: this.$t("campaigns.form.groups.template"),
          value: template,
          url: template,
          object: {
            type: "template"
          }
        };
        this.landings = [defaultObjLanding, ...this.companyLandings];
        this.attachments = [defaultObjAttachment, ...this.companyAttachments];
        this.form.landing = _.first(this.landings);
      } else {
        this.attachments = this.companyAttachments;
        this.landings = this.companyLandings;
        this.form.landing = _.first(this.landings);
      }
      this.postLandingTemplate = template;
    });
    this.$root.$on("updateDomain", () => (this.form.domain = this.domains[0]));
    this.$root.$on(
      "updateLanding",
      () => (this.form.landing = this.landings[0])
    );
    this.$root.$on(
      "updateCampaignTypeId",
      id => (this.form.campaignTypeId = id)
    );
    this.$root.$on(
      "updateCampaignName",
      name => (this.form.campaignName = name)
    );
    this.$root.$on("updateCampaignSubject", name => (this.form.subject = name));
    this.$root.$on("updateSubdomain", name => (this.form.subdomain = name));
    this.$root.$on("updateSender", name => (this.form.sender = name));
    this.$root.$on(
      "updateHeaders",
      name =>
        (this.form.headers = _.uniq(
          _.concat(
            _.filter(name, value => value != null),
            _.filter(this.companyHeaders, value => value != null)
          )
        ))
    );
  },
  beforeDestroy() {
    this.$root.$off("updateLandingTemplate", this.landingTemplate);
    this.$root.$off("updateAllowAttachment", this.showAttachment);
    this.$root.$off("updateMailTemplate", this.mailTemplate);
    this.$root.$off("updatePostLandingTemplate", this.postLandingTemplate);
    this.$root.$off("updateDomain", this.form.domain);
    this.$root.$off("updateCampaignTypeId", this.form.campaignTypeId);
    this.$root.$off("updateCampaignName", this.form.campaignName);
    this.$root.$off("updateCampaignSubject", this.form.subject);
    this.$root.$off("updateSubdomain", this.form.subdomain);
    this.$root.$off("updateSender", this.form.sender);
    this.$root.$off("updateHeaders", this.form.headers);
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadPhishing();
    },
    isCampaignCreated(value) {
      if (value === true) {
        this.$swal({
          title: this.$t("campaigns.form.created.title"),
          text: this.$t("campaigns.form.created.description", {
            module: this.$tc("menu.controller.phishing.title", 1)
          }),
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#ababab",
          confirmButtonText: this.$t("campaigns.form.created.createButton")
        }).then(() => {
          this.$router.push({ name: "ListCampaigns" });
        });
      }
    },
    areUsersLoaded(value) {
      if (this.isConstantsActive && value === false) this.loadPhishing();
    },
    form: {
      deep: true,
      inmediate: true,
      handler(value) {
        if (value.schedule_type === "now") {
          this.form.startDate_date = moment().format("YYYY-MM-DD");
          this.form.startDate_time = moment().format("HH:mm:ss");
        }
        if (
          value.template_type === "neurogaitas" &&
          this.hasModule(["neurogaitas"])
        ) {
          this.form.surveyTypeId = this.getConstant(
            "SURVEY_KYMATIO_NEUROPHISHING"
          );
          this.form.level = this.allLevels;
        } else if (value.template_type === "phishing") {
          this.form.surveyTypeId = this.getConstant("SURVEY_KYMATIO_PHISHING");
        } else if (value.template_type === "smishing") {
          this.form.surveyTypeId = this.getConstant("SURVEY_KYMATIO_SMISHING");
        } else {
          this.form.template_type = "phishing";
        }

        if (
          value.template_type === "smishing" ||
          value.template_type === "smishgaitas"
        ) {
          this.form.startDate_date = moment()
            .add(3, "days")
            .format("YYYY-MM-DD");
          if (this.form.schedule_type == "now") {
            this.$swal("", this.$t("campaigns.form.no_now_smishing"));
            this.form.schedule_type = "program";
          }
        }

        if (!_.eq(this.oldForm.tagKeys, value.tagKeys)) {
          this.form.tagValues = [];
        }

        if (
          !_.eq(this.oldForm.stakeholderSelection, value.stakeholderSelection)
        ) {
          if (value.stakeholderSelection) {
            this.form.departments = [];
          } else {
            this.form.tagKeys = [];
            this.form.tagValues = [];
          }
        }

        this.oldForm = _.clone(value);
      }
    }
  }
};
</script>

<style lang="scss">
.is-invalid .multiselect__tags {
  border: solid 1px #dc3545;
}
iframe.templateiframe {
  border: 0;
  pointer-events: none;
}

#create-campaign
  .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
#create-campaign .btn.btn-primary:focus:not(.btn-text),
#create-campaign .btn.btn-primary.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #0bb783;
  border-color: #0bb783;
}
</style>
