<template>
  <div v-if="rankings.length > 0" class="h-100">
    <div class="card card-custom gutter-b card-circuled card-stretch">
      <div v-for="ranking in slides" :key="ranking.name">
        <div v-if="ranking.visible" class="card-body h-100">
          <div class="slide-item w-100 h-100 ">
            <span
              class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-0 mt-6 d-block cursor-pointer"
            >
              {{ $t("dashboard.rankings.title", { name: ranking.name }) }}
            </span>
            <div
              class="d-flex justify-content-center w-100 h-100 align-items-center"
            >
              <div class="prev-btn">
                <b-button
                  @click="goPrevious(ranking.index)"
                  size="sm"
                  class="rounded-circle"
                  variant="secondary"
                >
                  <i class="fa fa-chevron-left" />
                </b-button>
              </div>

              <div class="w-100 h-50 rounded-sm rankingChart mx-2">
                <div
                  class="h-75 arrow-down bg-dark position-relative"
                  :ref="`ranking${ranking.name}`"
                  :style="
                    'width: 6px; left: ' + calculateWidth(ranking.value) + '%;'
                  "
                ></div>
              </div>
              <div class="trophy-icon mr-2">
                <i
                  class="fa fa-trophy"
                  style="color: #ffa800; font-size: 2rem;"
                ></i>
              </div>
              <div class="next-btn">
                <b-button
                  @click="goNext(ranking.index)"
                  size="sm"
                  class="rounded-circle"
                  variant="secondary"
                >
                  <i class="fa fa-chevron-right" />
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center m-2">
        <div v-for="(ranking, index) in slides" :key="index">
          <b-button
            @click="goToRanking(ranking.index)"
            size="sm"
            class="circle mx-1 p-2"
            :variant="current == index ? 'dark' : 'secondary'"
          />
        </div>
      </div>
      <div class="rankingPercentage text-success">
        <h3>
          {{
            $t("dashboard.rankings.position", {
              position: slides[current].position
            })
          }}
        </h3>
      </div>
    </div>
  </div>
  <div v-else class="h-100">
    <div
      class="card card-custom gutter-b card-circuled card-stretch d-flex justify-content-center"
    >
      <span
        class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-0 d-block cursor-pointer"
      >
        {{ $t("dashboard.rankings.empty") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ranking",
  props: {
    rankings: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      slides: [],
      current: 0
    };
  },
  methods: {
    calculateWidth(value) {
      return value - 1;
    },
    goNext(index) {
      if (index < this.slides.length - 1) {
        this.slides[index].visible = false;
        this.slides[index + 1].visible = true;
        this.current = index + 1;
      }
    },
    goPrevious(index) {
      if (index > 0) {
        this.slides[index].visible = false;
        this.slides[index - 1].visible = true;
        this.current = index - 1;
      }
    },
    goToRanking(index) {
      this.slides[this.current].visible = false;
      this.slides[index].visible = true;
      this.current = index;
    }
  },
  created() {
    this.slides = this.rankings.map((ranking, index) => ({
      ...ranking,
      visible: index === 0
    }));
    this.current = 0;
  }
};
</script>

<style>
.rankingChart {
  background: linear-gradient(
    90deg,
    rgba(246, 78, 96, 1) 0%,
    rgb(255, 168, 0) 54%,
    rgba(27, 197, 189, 1) 100%
  );
}
.arrow-down::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid black; /* Adjust the color as necessary */
  margin-top: -20px; /* Adjust this value depending on the div's position */
}
</style>
