import Vue from "vue";
import i18n from "@/plugins/i18n";
import store from "@/store";

const END_POINT = "/admin/stakeholders";

const getAdminStakeholder = (stakeholderType, stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/${stakeholderType}/${stakeholderId}`, {
    params: params
  });

const getServices = () => {
  return [
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_AWARENESS"),
      name: i18n.t("admin.form.surveyflow.surveyflows.awareness"),
      service: "awareness"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_WELLBEING"),
      name: i18n.t("admin.form.surveyflow.surveyflows.wellbeing"),
      service: "wellbeing"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_RISK"),
      name: i18n.t("admin.form.surveyflow.surveyflows.risk"),
      service: "risk"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_PHISHING"),
      name: i18n.t("admin.form.surveyflow.surveyflows.phishing"),
      service: "phishing"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_ABS"),
      name: i18n.t("admin.form.surveyflow.surveyflows.abs"),
      service: "abs"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_FEDERATION"),
      name: i18n.t("admin.form.surveyflow.surveyflows.federation"),
      service: "federation"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_NEUROGAITAS"),
      name: i18n.t("admin.form.surveyflow.surveyflows.neurophishing"),
      service: "neurogaitas"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_BURNOUT"),
      name: i18n.t("admin.form.surveyflow.surveyflows.burnout"),
      service: "burnout"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_SCIM"),
      name: i18n.t("admin.form.surveyflow.surveyflows.scim"),
      service: "scim"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_SMISHING"),
      name: i18n.t("admin.form.surveyflow.surveyflows.smishing"),
      service: "smishing"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_SMISHGAITAS"),
      name: i18n.t("admin.form.surveyflow.surveyflows.neurosmishing"),
      service: "smishgaitas"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_IMPACT"),
      name: i18n.t("admin.form.surveyflow.surveyflows.impact"),
      service: "impact"
    },
    {
      id: store.getters["Constants/getConstant"](
        "SERVICETYPE_SOCIALENGINEERING"
      ),
      name: i18n.t("admin.form.surveyflow.surveyflows.socialengineering"),
      service: "socialengineering"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_ARCHETYPE"),
      name: i18n.t("admin.form.surveyflow.surveyflows.archetype"),
      service: "archetype"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_GAMING_RANKING"),
      name: i18n.t("admin.form.surveyflow.surveyflows.gaming_ranking"),
      service: "gaming_ranking"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_CUSTOM_SURVEYS"),
      name: i18n.t("admin.form.surveyflow.surveyflows.custom_surveys"),
      service: "custom_surveys"
    },
    {
      id: store.getters["Constants/getConstant"](
        "SERVICETYPE_GAMING_ACHIEVEMENTS"
      ),
      name: i18n.t("admin.form.surveyflow.surveyflows.gaming_achievements"),
      service: "gaming_achievements"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_GAMING_GAMES"),
      name: i18n.t("admin.form.surveyflow.surveyflows.gaming_games"),
      service: "gaming_games"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_MULTI_RISK"),
      name: i18n.t("admin.form.surveyflow.surveyflows.multi_risk"),
      service: "multi_risk"
    },
    {
      id: store.getters["Constants/getConstant"]("SERVICETYPE_MFA"),
      name: i18n.t("admin.form.surveyflow.surveyflows.mfa"),
      service: "mfa"
    }
  ];
};

const updateStakeholder = (
  stakeholderType,
  stakeholderId,
  payload,
  chat = true
) =>
  Vue.axios.put(`${END_POINT}/${stakeholderType}/${stakeholderId}`, payload, {
    chat: chat
  });
const updateStakeholderLogo = (stakeholderType, stakeholderId, payload) =>
  Vue.axios.post(`${END_POINT}/${stakeholderType}/${stakeholderId}`, payload, {
    "Content-Type": "multipart/form-data"
  });
const sendWellcomeMail = (stakeholderId, payload) =>
  Vue.axios.post(`${END_POINT}/emails/${stakeholderId}`, payload);
const deleteStakeholder = (stakeholderType, stakeholderId) =>
  Vue.axios.delete(`${END_POINT}/${stakeholderType}/${stakeholderId}`);
const deleteStakeholders = (stakeholderType, payload) =>
  Vue.axios.put(`${END_POINT}/multidelete/${stakeholderType}`, payload);
const deleteCompanyChildren = (stakeholderType, stakeholderId) =>
  Vue.axios.delete(`${END_POINT}/${stakeholderType}/${stakeholderId}`);
const createStakeholder = (stakeholderType, payload) =>
  Vue.axios.post(`${END_POINT}/${stakeholderType}`, payload);
const getDepartments = (stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/companies/${stakeholderId}/departments`, {
    params: params
  });
const getUsers = (stakeholderType, stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/${stakeholderType}/${stakeholderId}/people`, {
    params: params
  });
const getCompanies = (stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/companies/${stakeholderId}/companies`, {
    params: params
  });
const getUserAdminCompanies = (stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/people/${stakeholderId}/companies`, {
    params: params
  });
const reactiveStakeholder = stakeholderId =>
  Vue.axios.patch(`${END_POINT}/people/${stakeholderId}`);

const sendExcelUsersData = (stakeholderId, payload, chat = true) =>
  Vue.axios.post(`${END_POINT}/excel/people/${stakeholderId}`, payload, {
    chat: chat
  });

const sendExcelDepartmentsData = (stakeholderId, payload, chat = true) =>
  Vue.axios.post(`${END_POINT}/excel/departments/${stakeholderId}`, payload, {
    chat: chat
  });

const getTags = (stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/tags/${stakeholderId}`, { params: params });

const insertTagValue = (tagId, stakeholderId, payload) =>
  Vue.axios.put(
    `${END_POINT}/tags/${tagId}/${stakeholderId}`,
    {},
    { params: payload }
  );

const deleteTagValue = (tagId, stakeholderId, payload) =>
  Vue.axios.delete(`${END_POINT}/tags/${tagId}/${stakeholderId}`, {
    params: payload
  });

const getStakeholders = (
  stakeholderTypeParent,
  stakeholderId,
  stakeholderTypeChildren,
  payload,
  params = {},
  showLoading = true
) =>
  Vue.axios.post(
    `${END_POINT}/${stakeholderTypeParent}/${stakeholderId}/${stakeholderTypeChildren}`,
    payload,
    { params: params },
    { chat: !showLoading }
  );

export {
  getServices,
  getAdminStakeholder,
  updateStakeholder,
  updateStakeholderLogo,
  deleteStakeholder,
  deleteStakeholders,
  deleteCompanyChildren,
  sendWellcomeMail,
  createStakeholder,
  getDepartments,
  getUsers,
  getCompanies,
  reactiveStakeholder,
  getUserAdminCompanies,
  sendExcelUsersData,
  sendExcelDepartmentsData,
  getTags,
  insertTagValue,
  deleteTagValue,
  getStakeholders
};
