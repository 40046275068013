import Vue from "vue";
import _ from "lodash";

const END_POINT = "/controller/traits";

const getInteractions = (stakeholderId, dummies = true, params = {}) =>
  Vue.axios.get(`${END_POINT}/${stakeholderId}/interactions`, {
    params: _.merge(params, { dummies: dummies })
  });
const getProfile = (stakeholderId, params, chat = false) =>
  Vue.axios.get(`${END_POINT}/${stakeholderId}/psychoprofile`, {
    params: params,
    chat: chat
  });
const getAlerts = (stakeholderId, params) =>
  Vue.axios.get(`${END_POINT}/${stakeholderId}/alerts`, { params: params });
const getStakeholderActions = (stakeholderId, params) =>
  Vue.axios.get(`${END_POINT}/${stakeholderId}/actions`, { params: params });
const getStakeholdersActions = (
  parentType,
  childrenType,
  stakeholderId,
  params
) =>
  Vue.axios.get(
    `${END_POINT}/${parentType}/${stakeholderId}/${childrenType}/actions`,
    { params: params }
  );
const getActionsTexts = params =>
  Vue.axios.get(`${END_POINT}/text-actions`, { params: params });
const getStakeholderAssets = (stakeholderId, surveyEntityId = false) => {
  if (surveyEntityId) {
    return Vue.axios.get(
      `${END_POINT}/${stakeholderId}/assets/${surveyEntityId}`
    );
  } else {
    return Vue.axios.get(`${END_POINT}/${stakeholderId}/assets`);
  }
};

export {
  getInteractions,
  getProfile,
  getAlerts,
  getStakeholderActions,
  getStakeholdersActions,
  getActionsTexts,
  getStakeholderAssets
};
