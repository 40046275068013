import Vue from "vue";

const END_POINT = "/controller/campaigns";

const getCampaigns = (stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/${stakeholderId}`, { params: params });
const getCampaign = (stakeholderId, campaignId) =>
  Vue.axios.get(`${END_POINT}/${stakeholderId}/${campaignId}`);
const createCampaign = (stakeholderId, payload) =>
  Vue.axios.post(`${END_POINT}/${stakeholderId}`, payload);
const createScheduledCampaign = (stakeholderId, payload) =>
  Vue.axios.post(`${END_POINT}/${stakeholderId}/schedule`, payload);
const updateCampaign = (stakeholderId, campaignId, payload) =>
  Vue.axios.put(`${END_POINT}/${stakeholderId}/${campaignId}`, payload);
const deleteCampaign = (stakeholderId, campaignId) =>
  Vue.axios.delete(`${END_POINT}/${stakeholderId}/${campaignId}`);
const getTemplate = (stakeholderId, templateId) =>
  Vue.axios.get(`${END_POINT}/${stakeholderId}/templates/${templateId}`);
const getTypes = stakeholderId =>
  Vue.axios.get(`${END_POINT}/${stakeholderId}/types`);
const getEntityProfile = (companyStakeholderId, stakeholderId, params) =>
  Vue.axios.get(
    `${END_POINT}/${companyStakeholderId}/${stakeholderId}/psychoprofile`,
    {
      params: params
    }
  );
const getStakeholderCampaigns = (
  companyStakeholderId,
  stakeholderId,
  params = {}
) =>
  Vue.axios.get(
    `${END_POINT}/${companyStakeholderId}/${stakeholderId}/interactions`,
    {
      params: params
    }
  );
const getSubcampaigns = (stakeholderId, campaignMasterId, params = {}) =>
  Vue.axios.get(
    `${END_POINT}/${stakeholderId}/subcampaigns/${campaignMasterId}`,
    {
      params: params
    }
  );
const getSubcampaign = (
  stakeholderId,
  campaignMasterId,
  campaignId,
  params = {}
) =>
  Vue.axios.get(
    `${END_POINT}/${stakeholderId}/subcampaigns/${campaignMasterId}/${campaignId}`,
    {
      params: params
    }
  );
const getDomains = stakeholderId =>
  Vue.axios.get(`${END_POINT}/${stakeholderId}/domains`);
const getLandings = stakeholderCompanyId =>
  Vue.axios.get(`${END_POINT}/${stakeholderCompanyId}/landings`);
const getAttachments = stakeholderCompanyId =>
  Vue.axios.get(`${END_POINT}/${stakeholderCompanyId}/attachments`);
const getHeaders = stakeholderCompanyId =>
  Vue.axios.get(`${END_POINT}/${stakeholderCompanyId}/smtpheaders`);

export {
  getCampaigns,
  getCampaign,
  createCampaign,
  createScheduledCampaign,
  updateCampaign,
  deleteCampaign,
  getTemplate,
  getTypes,
  getEntityProfile,
  getStakeholderCampaigns,
  getSubcampaigns,
  getSubcampaign,
  getDomains,
  getLandings,
  getAttachments,
  getHeaders
};
