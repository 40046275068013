import Vue from "vue";

const END_POINT = "/auth";

const login = payload => Vue.axios.post(`${END_POINT}/login`, payload);
const logout = () => Vue.axios.get(`${END_POINT}/logout`);
const language = payload => Vue.axios.post(`${END_POINT}/language`, payload);
const forgotPassword = payload =>
  Vue.axios.post(`${END_POINT}/forgotpassword`, payload);
const checkToken = payload =>
  Vue.axios.post(`${END_POINT}/checktoken`, payload);
const resetPassword = (payload, from = "email") =>
  Vue.axios.post(`${END_POINT}/resetpassword/${from}`, payload);
const acceptConditions = (payload, headers) =>
  Vue.axios.post(`${END_POINT}/aceptconditions`, payload, headers);
const assets = url => Vue.axios.get(url);
const info = params => Vue.axios.get(`${END_POINT}/info`, { params: params });
const renewtotp = payload => Vue.axios.post(`${END_POINT}/renewtopt`, payload);

export {
  login,
  logout,
  language,
  forgotPassword,
  checkToken,
  resetPassword,
  acceptConditions,
  assets,
  info,
  renewtotp
};
