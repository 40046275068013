import _ from "lodash";
import { getConstants } from "@/api/misc.api.js";
import axios from "axios";

const state = {
  status: "",
  constants: {},
  translations: {}
};

const getters = {
  getConstant: state => {
    return name => {
      return state.constants[name];
    };
  },
  constStatus: state => state.status,
  isConstantsActive: state => state.status === "success",
  getConstantsByType: state => type => {
    return _.pickBy(state.constants, (item, key) => {
      return _.includes(key, type);
    });
  },
  getDimensionTranslation: state => {
    return (name, entity = "default") =>
      state.translations[entity] ? state.translations[entity][name] : null;
  }
};

const actions = {
  async loadConstants({ commit }) {
    try {
      let requests = [
        getConstants(
          "HIERARCHYTYPE_,SURVEY_KYMATIO,DIMENSION_GROUP_,ACTIONS_,DIMENSION_ELEMENT_,ENTITY_TYPE_,SURVEYTYPE,SERVICETYPE_,NULL_DATE,SURVEY_STATUS_,AUTHENTICATION_,TAG_,STAKEHOLDER_TYPE_"
        )
      ];
      axios.all(requests).then(
        axios.spread(constantsResponse => {
          commit("set_constants", constantsResponse.data.records);
        })
      );
    } catch (error) {
      commit("set_error", error);
    }
  },
  reset({ commit }) {
    commit("reset");
  }
};

const mutations = {
  set_constants(state, data) {
    state.status = "success";
    state.constants = data;
  },
  set_translations(state, data) {
    state.translations = data;
  },
  set_error(state) {
    state.status = "error";
  },
  reset(state) {
    state.constants = [];
    state.translations = [];
    state.status = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
