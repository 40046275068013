<template>
  <b-modal
    size="xl"
    class="scrolleable-modal"
    :id="`modal-template-preview-${id}`"
    :title="item.campaignName"
    @show="callGetTemplates()"
    :scrollable="true"
  >
    <!--begin::header modal-->

    <template #modal-header="{ close }">
      <h5>{{ item.campaignName }}</h5>
      <b-button variant="link" @click="campaignMode ? close() : closeModal()">
        <b-icon-x scale="2"></b-icon-x>
      </b-button>
    </template>
    <!--end::header modal-->
    <div v-if="!campaignMode">
      <div class="form-group row my-2">
        <label class="col-2 col-form-label">{{ $tc(`tables.sender`) }}</label>
        <div class="col-10">
          <span class="form-control-plaintext font-weight-bolder">
            {{ templates.sender }}
          </span>
        </div>
      </div>
      <div class="form-group row my-2">
        <label class="col-2 col-form-label">{{ $tc(`tables.subject`) }}</label>
        <div class="col-10">
          <span class="form-control-plaintext font-weight-bolder">
            {{ templates.subject }}
          </span>
        </div>
      </div>
      <div class="form-group row my-2" v-if="headers">
        <label class="col-2 col-form-label">{{ $tc(`tables.headers`) }}</label>
        <div class="col-10">
          <span
            class="form-control-plaintext font-weight-bolder"
            v-for="(header, index) in headers"
            :key="index"
          >
            {{ header.key }}: {{ header.value }}
          </span>
        </div>
      </div>
    </div>
    <!--begin::campaign template-->
    <campaign-template
      v-if="landingTemplate !== undefined && mailTemplate !== undefined"
      :landingTemplate="landingTemplate"
      :mailTemplate="mailTemplate"
      :postlandingTemplate="postlandingTemplate"
      :subject="subject"
    ></campaign-template>
    <!--end::campaign template-->
    <!--begin::footer modal-->
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-if="campaignMode"
          variant="primary"
          class="float-right"
          @click="selectTemplate()"
          :disabled="!loadTemplate"
        >
          {{ $t("tables.select") }}
        </b-button>
      </div>
    </template>
    <!--end::footer modal-->
  </b-modal>
</template>

<script>
import { getTemplate } from "@/api/controller/campaigns.api";
import CampaignTemplate from "@/components/campaign/CampaignTemplate.component.vue";
import { BIconX } from "bootstrap-vue";
import _ from "lodash";

export default {
  name: "TemplatePreview",
  data() {
    return {
      landingTemplate: undefined,
      mailTemplate: undefined,
      subject: undefined,
      postlandingTemplate: undefined,
      loadTemplate: false,
      subdomainTemplate: "",
      senderTemplate: "",
      allowAttachment: false,
      headersTemplate: []
    };
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    campaignMode: {
      default: true,
      type: Boolean,
      required: false
    },
    templates: {
      type: Object,
      required: false
    },
    sender: {
      type: String,
      required: false
    },
    extraHeaders: {
      required: false
    }
  },
  components: {
    CampaignTemplate,
    BIconX
  },
  computed: {
    headers() {
      return _.uniq(
        _.concat(
          _.filter(this.templates?.headers, value => value != null),
          _.filter(this.extraHeaders, value => value != null)
        )
      );
    }
  },
  methods: {
    callGetTemplates() {
      const stakeholderId = localStorage.getItem("managedCompanyId");
      if (this.campaignMode) {
        getTemplate(stakeholderId, this.item.campaignTypeId).then(response => {
          this.loadTemplate = false;
          const template = response.data.records;
          this.landingTemplate = template.landing;
          this.mailTemplate = template.mail;
          this.postlandingTemplate = template.url_redirect;
          this.subject = template.subject;
          this.loadTemplate = template ? true : false;
          this.subdomainTemplate = template.subdomain;
          this.allowAttachment = template.allowAttach;
          this.headersTemplate = template.headers;
          this.senderTemplate = template.sender_envelop
            ? `${template.sender_envelop} <${template.sender}`
            : template.sender;
        });
      } else {
        this.landingTemplate = this.templates.landing;
        this.mailTemplate = this.templates.mail;
        this.postlandingTemplate = this.templates.url_redirect;
        this.subject = "";
      }
    },
    closeModal() {
      this.$bvModal.hide(`modal-template-preview-${this.id}`);
    },
    selectTemplate() {
      this.$root.$emit("updateLandingTemplate", this.landingTemplate);
      this.$root.$emit("updateAllowAttachment", this.allowAttachment);
      this.$root.$emit("updateMailTemplate", this.mailTemplate);
      this.$root.$emit("updateCampaignSubject", this.subject);
      this.$root.$emit("updatePostLandingTemplate", this.postlandingTemplate);
      this.$root.$emit("updateDomain");
      this.$root.$emit("updateCampaignTypeId", this.item.campaignTypeId);
      this.$root.$emit("updateCampaignName", this.item.campaignName);
      this.$root.$emit("updateSender", this.senderTemplate);
      this.$root.$emit("updateSubdomain", this.subdomainTemplate);
      this.$root.$emit("updateHeaders", this.headersTemplate);
      this.$emit("select-row", this.id);
      this.$bvModal.hide(`modal-template-preview-${this.id}`);
    }
  }
};
</script>
