<template>
  <div v-if="cloneData.length && !loading">
    <b-table
      id="latest-breaches-users-department-table"
      hover
      :fields="fields"
      :items="items"
      responsive="sm"
      stacked="sm"
      :per-page="0"
      @sort-change="handleSortChange"
      @context-changed="handleSortChange"
      small
    >
      <template #cell(resolved)="row">
        <b-icon-check
          variant="success"
          scale="2"
          v-if="completed(row.item)"
        ></b-icon-check>
        <b-icon-exclamation-triangle
          scale="2"
          variant="danger"
          v-else
        ></b-icon-exclamation-triangle>
      </template>
      <template #cell(actions)="row">
        <b-button
          pill
          variant="light-primary"
          class="mr-2 px-auto"
          @click="viewDetail(row.item.stakeholderId)"
        >
          <i
            class="la la-eye pl-1"
            v-b-tooltip.right.hover
            :title="$t('tables.seeDetail')"
          />
        </b-button>
      </template>
    </b-table>

    <div class="flex-wrap mx-auto" v-if="total > 0">
      <b-pagination
        class="d-flex flex-wrap justify-content-center align-items-center mt-3 py-2 mr-3"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        aria-controls="companies-table"
        first-number
        pill
        last-number
        size="lg"
        @change="loadElements"
      ></b-pagination>
    </div>
  </div>
  <div v-else>
    <empty-card ype="department.abs" />
  </div>
</template>

<script>
import _ from "lodash";
import EmptyCard from "@/components/EmptyCard.component";
import { BIconCheck, BIconExclamationTriangle } from "bootstrap-vue";
import { getStakeholders } from "@/api/controller/stakeholders.api";

export default {
  name: "LatestBreachesUsersCompany",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      cloneData: [],
      loading: false
    };
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  components: {
    EmptyCard,
    BIconCheck,
    BIconExclamationTriangle
  },
  computed: {
    items() {
      return _.slice(
        this.cloneData,
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    fields() {
      return [
        {
          key: "name",
          label: this.$tc("tables.name", 1),
          sortable: false,
          sortByFormatted: false,
          class: "align-middle text-left",
          formatter: (value, key, item) => {
            return item.user?.name + " " + item.user?.surname;
          }
        },
        {
          key: "lastBreachDate",
          label: this.$tc("tables.breachDate", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "breachesPwd",
          label: this.$tc("tables.breachespsw", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          formatter: (value, key, item) => {
            return !item.pwdSolved && !item.pwd
              ? "-"
              : `${item.pwdSolved} / ${item.pwd}`;
          }
        },
        {
          key: "breachesNoPwd",
          label: this.$tc("tables.breachesnopsw", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          formatter: (value, key, item) => {
            return !item.noPwdSolved && !item.noPwd
              ? "-"
              : `${item.noPwdSolved} / ${item.noPwd}`;
          }
        },
        {
          key: "resolved",
          label: this.$tc("tables.resolved", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-center",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return this.completed(item);
          }
        },
        {
          key: "actions",
          label: this.$t("tables.actions"),
          sortable: false,
          class: "align-middle"
        }
      ];
    },
    total() {
      return _.size(this.cloneData);
    }
  },
  methods: {
    loadElements(actualPage) {
      this.loading = true;
      let stakeholderCompanyId = localStorage.getItem("managedCompanyId");
      let page = actualPage;
      let stakeholders = _.map(
        _.filter(
          _.slice(
            this.cloneData,
            (page - 1) * this.perPage,
            page * this.perPage
          ),
          element => element.user == undefined
        ),
        "stakeholderId"
      );

      if (_.size(stakeholders) > 0) {
        getStakeholders(
          "companies",
          stakeholderCompanyId,
          "people",
          {
            stakeholdersId: stakeholders
          },
          { name: true, surname: true, profile: false }
        ).then(response => {
          _.each(response.data.records, stakeholder => {
            let index = _.findIndex(this.cloneData, element => {
              return element.stakeholderId == stakeholder.stakeholderId;
            });
            if (index >= 0) {
              this.cloneData[index].user = stakeholder;
            }
          });
          this.loading = false;
          this.currentPage = actualPage;
        });
      } else {
        this.loading = false;
        this.currentPage = actualPage;
      }
    },
    viewDetail(stakeholderId) {
      this.$router.push({
        name: "UserDetail",
        params: {
          id: stakeholderId
        }
      });
    },
    completed(item) {
      return item.pwdSolved + item.noPwdSolved - (item.pwd + item.noPwd) == 0;
    },
    handleSortChange(sortInfo) {
      this.cloneData = _.orderBy(
        this.cloneData,
        element => {
          let field = _.find(this.fields, el => el.key == sortInfo.sortBy);
          var result = null;
          if (field) {
            if (field.formatter) {
              result = field.formatter(null, null, element);
            } else {
              result = element[field.key];
            }
          }

          return result;
        },
        sortInfo.sortDesc ? "desc" : "asc"
      );
      this.currentPage = 1;
      this.loadElements(this.currentPage);
    }
  },
  mounted() {
    this.cloneData = _.clone(this.data);
    this.loadElements(this.currentPage);
  }
};
</script>

<style lang="scss" scoped>
.card.card-custom.card-stretch.gutter-b {
  height: auto;
}
.has-badge {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.7rem;
}
.is-link {
  cursor: pointer;
}
</style>
