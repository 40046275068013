<template>
  <div id="view-campaign" v-if="dataLoaded">
    <div class="subheader py-2 py-lg-4 subheader-transparent">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Details-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            {{ this.$tc("tables.campaigns", 1) }}
          </h5>
        </div>
      </div>
      <div class="d-flex no-wrap align-items-center">
        <b-button
          tag="a"
          @click="$router.push({ name: 'ListCampaigns' })"
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder text-uppercase mr-2"
        >
          <div class="d-flex align-items-center">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
      </div>
    </div>
    <b-row class="mt-3">
      <b-col lg="4">
        <b-card
          class="card-custom card-rounded gutter-b"
          style="border-radius: 30px;"
          no-body
        >
          <!--begin::Header-->
          <div class="card-header h-auto pt-4">
            <div class="card-title">
              <h3 class="card-label text-left">
                {{ campaign.name }}
                <span class="d-block text-muted pt-2 font-size-sm"></span>
              </h3>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-4">
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">{{
                $t(`tables.startDate`)
              }}</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">{{
                  formattedDate(campaign.startDate)
                }}</span>
              </div>
            </div>
            <div class="form-group row my-2" v-if="campaign.sendByDate">
              <label class="col-4 col-form-label">{{
                $t(`tables.sendByDate`)
              }}</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">{{
                  formattedDate(campaign.sendByDate)
                }}</span>
              </div>
            </div>
            <div
              class="form-group row my-2"
              v-if="campaign.endDate !== getConstant('NULL_DATE')"
            >
              <label class="col-4 col-form-label">{{
                $t(`tables.endDate`)
              }}</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">{{
                  formattedDate(campaign.endDate)
                }}</span>
              </div>
            </div>
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">{{
                $tc(`tables.categories`, 1)
              }}</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">
                  {{ campaign.campaignCategory }}
                </span>
              </div>
            </div>
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">{{
                $tc(`tables.campaignLevel`)
              }}</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">
                  {{ campaign.campaignLevel }}
                </span>
              </div>
            </div>
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">{{
                $tc(`tables.url`)
              }}</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">
                  {{ config.url }}
                </span>
              </div>
            </div>
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">{{
                $tc(`tables.templates`, 1)
              }}</label>
              <div class="col-8">
                <div
                  class="d-flex align-items-center justify-content-center flex-wrap"
                >
                  <div v-if="hasMoreThanOne">
                    <b-dropdown variant="link" :text="config.name">
                      <b-dropdown-item
                        v-for="(config, index) in subcampaigns.configuration"
                        :key="index"
                        @click="selectSubcampaign(config, index)"
                        :v-b-modal="`modal-template-preview-${index}`"
                        :ref="`btn-${index}`"
                      >
                        {{ config.name }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div v-else>
                    <span
                      class="form-control-plaintext font-weight-bolder mr-2"
                    >
                      {{ subcampaignName }}
                    </span>
                  </div>
                  <div class="open-templates-preview">
                    <span
                      v-b-tooltip.right.hover
                      variant="info"
                      class="font-weight-bolder label label-xl label-inline px-3 py-5 min-w-50px"
                      :style="{ cursor: 'pointer' }"
                      :title="$t('tables.seeDetail')"
                      @click="openSubcampaignConfig"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M3 12s2.455-6 9-6c4.91 0 9 6 9 6s-4.09 6-9 6c-6.545 0-9-6-9-6z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M12 15a3 3 0 110-6 3 3 0 010 6z" fill="#000" opacity=".3"/></g></svg>
                    </span>
                  </div>
                  <div v-if="isSubcampaignSelected">
                    <template-preview
                      :id="modalIndex"
                      :item="subcampaign"
                      :campaignMode="false"
                      :templates="config"
                      :extraHeaders="companyHeaders"
                    ></template-preview>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">
                {{ $t("tables.status.header") }}
              </label>
              <div class="col-8">
                <b-badge
                  variant="success"
                  v-if="getStatus(campaign) === 'finished'"
                  >{{ $t(`tables.status.${getStatus(campaign)}`) }}</b-badge
                >
                <b-badge
                  variant="info"
                  v-if="getStatus(campaign) === 'progress'"
                  >{{ $t(`tables.status.${getStatus(campaign)}`) }}</b-badge
                >
                <b-badge
                  variant="warning"
                  v-if="getStatus(campaign) === 'programmed'"
                  >{{ $t(`tables.status.${getStatus(campaign)}`) }}</b-badge
                >
                <b-badge
                  variant="dark"
                  class="bg-gray-600"
                  v-if="getStatus(campaign) === 'canceled'"
                  >{{ $t(`tables.status.${getStatus(campaign)}`) }}</b-badge
                >
              </div>
            </div>
            <div class="form-group row my-2" v-if="campaign.asset">
              <label class="col-4 col-form-label">{{
                $tc(`tables.report`, 1)
              }}</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">
                  <b-btn
                    @click="apiCall(campaign.asset.url, campaign.asset.link)"
                    size="sm"
                    variant="info"
                  >
                    <i class="fas fa-download" />
                    {{ $t("generic.buttons.download") }}
                  </b-btn>
                </span>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </b-card>
      </b-col>
      <b-col lg="8" v-if="dimensions.length > 0">
        <b-card class="card card-custom card-stretch gutter-b pt-4">
          <b-row align-v="center" align-content="stretch">
            <b-col cols v-for="dimension in dimensions" :key="dimension.id">
              <div
                :class="
                  `chart-title font-weight-bolder text-${dimension.class}`
                "
              >
                {{
                  $t(
                    `tables.dimensionIds.dimension.${dimension.id}.COMPANY.description`
                  )
                }}
              </div>
            </b-col>
          </b-row>
          <b-row align-v="center" align-content="stretch">
            <b-col cols v-for="dimension in dimensions" :key="dimension.id">
              <apexchart
                type="radialBar"
                height="250"
                :options="chartOptions(dimension)"
                :series="[dimension.value]"
              ></apexchart>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      align-v="center"
      align-content="stretch"
      v-if="Object.keys(campaign).length > 0 && loaded"
    >
      <b-col>
        <b-card no-body class="card-custom gutter-b">
          <b-tabs
            pills
            card
            content-class="card-body pt-1"
            nav-class="card-toolbar nav-light-success font-weight-bolder"
          >
            <b-tab :title="$t('campaigns.tabs.employees')" active>
              <b-table
                class="pt-0 mt-0"
                id="employees-table"
                :items="filteredEmployees"
                :fields="employeeFields"
                :current-page="employeesCurrentPage"
                primary-key="stakeholderId"
                :responsive="true"
                show-empty
                :borderless="true"
                :small="true"
                sort-icon-left
                :per-page="0"
                @sort-change="handleSortChange"
                @context-changed="handleSortChange"
              >
                <template #cell(name)="row">
                  <div class="d-flex align-items-center">
                    <div class="w-40px align-middle pb-6 pl-0 mr-2 pr-4">
                      <avatar
                        size="sm"
                        :showArchetype="false"
                        :avatar="
                          getUserData(row.item.stakeholderId).avatar ||
                            '000-Empty.svg'
                        "
                      />
                    </div>
                    <span
                      class="font-size-lg font-weight-bolder text-dark-75 align-middle w-300px pb-6 text-left"
                    >
                      {{ row.value }}
                    </span>
                  </div>
                </template>
                <template #cell(status)="row">
                  <span
                    :class="
                      `label label-inline label-light-${
                        getLevelByStakeholder(row.item).class
                      } font-weight-bolder p-6`
                    "
                    style="font-size: 1rem;"
                    >{{
                      $t(
                        `campaigns.levels.${
                          getLevelByStakeholder(row.item).value
                        }`
                      )
                    }}</span
                  >
                  <span
                    v-if="isReported(row.item)"
                    class="label label-inline label-light-primary font-weight-bolder p-6 ml-4"
                    style="font-size: 1rem;"
                    >{{ $t(`campaigns.levels.1`) }}</span
                  >
                </template>
              </b-table>
              <div class="flex-wrap mx-auto" v-if="totalEmployees > 0">
                <b-pagination
                  class="custom-pagination"
                  v-model="employeesCurrentPage"
                  :total-rows="totalEmployees"
                  :per-page="employeesPerPage"
                  aria-controls="employees-table"
                  first-number
                  last-number
                  align="center"
                  size="md"
                  @change="loadElements"
                >
                  <template #first-text>
                    <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                  </template>
                  <template #prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>
                  <template #next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>
                  <template #last-text>
                    <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                  </template>
                </b-pagination>
              </div>
            </b-tab>
            <b-tab :title="$t('campaigns.tabs.departments')">
              <b-table
                class="pt-0 mt-0"
                id="departments-table"
                :items="filteredDepartments"
                :fields="departmentsFields"
                :per-page="departmentsPerPage"
                :current-page="departmentsCurrentPage"
                primary-key="stakeholderId"
                :responsive="true"
                show-empty
                :borderless="true"
                :small="true"
                sort-icon-left
                v-if="filteredDepartments.length > 0"
              >
                <template #cell(name)="row">
                  <div class="d-flex align-items-center">
                    <div class="align-middle pb-6 pl-0 mr-2 pr-4">
                      <div class="symbol symbol-20 symbol-lg-60">
                        <span
                          :class="
                            'font-size-h3 symbol-label font-weight-boldest'
                          "
                        >
                          {{ getInitials({ name: row.value }) }}
                        </span>
                      </div>
                      <span
                        class="font-size-lg font-weight-bolder text-dark-75 align-middle w-300px pb-6 text-left ml-4"
                      >
                        {{ row.value }}
                      </span>
                    </div>
                  </div>
                </template>
                <template #cell(DIMENSION_ELEMENT_PHISHING_EMAIL_SENT)="row">
                  <span
                    :class="
                      `label label-inline label-light-success font-weight-bolder p-6`
                    "
                    style="font-size: 1rem;"
                  >
                    {{ row.value }}
                  </span>
                </template>
                <template #cell(DIMENSION_ELEMENT_PHISHING_EMAIL_OPENED)="row">
                  <span
                    :class="
                      `label label-inline label-light-info font-weight-bolder p-6`
                    "
                    style="font-size: 1rem;"
                  >
                    {{ row.value }}
                    <span>/ {{ getDepartmentBase(row.item) }}</span>
                  </span>
                </template>
                <template #cell(DIMENSION_ELEMENT_PHISHING_CLICKED_LINK)="row">
                  <span
                    :class="
                      `label label-inline label-light-warning font-weight-bolder p-6`
                    "
                    style="font-size: 1rem;"
                  >
                    {{ row.value }}
                    <span>/ {{ getDepartmentBase(row.item) }}</span>
                  </span>
                </template>
                <template
                  #cell(DIMENSION_ELEMENT_PHISHING_SUBMITTED_DATA)="row"
                >
                  <span
                    :class="
                      `label label-inline label-light-danger font-weight-bolder p-6`
                    "
                    style="font-size: 1rem;"
                  >
                    {{ row.value }}
                    <span>/ {{ getDepartmentBase(row.item) }}</span>
                  </span>
                </template>
              </b-table>
              <div class="flex-wrap mx-auto" v-if="totalDepartments > 0">
                <b-pagination
                  class="custom-pagination"
                  v-model="departmentsCurrentPage"
                  :total-rows="totalDepartments"
                  :per-page="departmentsPerPage"
                  aria-controls="departments-table"
                  first-number
                  last-number
                  align="center"
                  size="md"
                >
                  <template #first-text>
                    <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                  </template>
                  <template #prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>
                  <template #next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>
                  <template #last-text>
                    <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                  </template>
                </b-pagination>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <empty-campaign
      :description="$t(`tables.empty_phishing.description`)"
    ></empty-campaign>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import _ from "lodash";
import { extractValueFromProfile } from "@/utils/helpers";
import EmptyCampaign from "@/components/EmptyCampaign.vue";

import {
  getCampaign,
  getSubcampaigns,
  getEntityProfile,
  getSubcampaign,
  getHeaders
} from "@/api/controller/campaigns.api";
import { getStakeholders } from "@/api/controller/stakeholders.api";

import Avatar from "@/components/profile/Avatar.component.vue";
import TemplatePreview from "@/components/campaign/TemplatePreview.component.vue";

export default {
  name: "ViewCampaign",
  components: {
    Avatar,
    TemplatePreview,
    EmptyCampaign
  },
  data() {
    return {
      campaignId: false,
      dimensions: [],
      campaign: {},
      base: 0,
      allUsers: [],
      allDepartments: false,
      usersProfiles: [],
      departmentsProfiles: [],
      subcampaigns: [],
      employees: [],
      departments: [],
      loaded: false,
      employeesPerPage: 10,
      employeesCurrentPage: 1,
      departmentsPerPage: 10,
      departmentsCurrentPage: 1,
      employeeFilters: {
        status: []
      },
      modalIndex: 0,
      subcampaign: {},
      subcampaignName: "",
      isSubcampaignSelected: false,
      config: []
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant", "isConstantsActive"]),
    ...mapGetters("User", ["company"]),
    ...mapGetters("Controller", ["companySelected"]),
    hasMoreThanOne() {
      return this.subcampaigns.configuration?.length > 1;
    },
    filteredEmployees() {
      var employees = _.slice(
        this.employees,
        (this.employeesCurrentPage - 1) * this.departmentsPerPage,
        this.employeesCurrentPage * this.departmentsPerPage
      );

      return employees;
    },
    dataLoaded() {
      return (
        !_.isEmpty(this.subcampaigns) &&
        !_.isEmpty(this.campaign) &&
        this.allDepartments !== false
      );
    },
    totalEmployees() {
      return this.employees.length;
    },
    employeeFields() {
      return [
        {
          key: "name",
          label: this.$tc("tables.user", 1),
          formatter: (val, key, item) =>
            (this.getUserData(item.stakeholderId).name || "") +
            " " +
            (this.getUserData(item.stakeholderId).surname || ""),
          sortByFormatted: true,
          filterByFormatted: true,
          sortDirection: "desc",
          class: "font-weight-bold text-left align-middle",
          thClass: "h6",
          sortable: false
        },
        {
          key: "department",
          formatter: (val, key, item) =>
            this.getUserData(item.stakeholderId).stakeholderDepartmentName ||
            "",
          label: this.$tc("tables.department", 1),
          class: "font-weight-bold align-middle pb-6 text-left",
          trClass: "text-muted",
          thClass: "h6",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
        {
          key: "status",
          label: this.$t("tables.status.header"),
          formatter: (val, key, item) =>
            this.getLevelByStakeholder(item).value || 0,
          class: "font-weight-bold text-left align-middle",
          thClass: "h6",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        }
      ];
    },
    departmentsFields() {
      var result = [
        {
          key: "name",
          label: this.$tc("tables.department", 1),
          formatter: (val, key, item) =>
            this.getDepartmentData(item).name || "N/D",
          sortByFormatted: true,
          filterByFormatted: true,
          sortDirection: "desc",
          class: "font-weight-bold text-left align-middle",
          thClass: "h6",
          sortable: true
        }
      ];
      _.each(this.basicDimensions, dimension => {
        result.push({
          key: dimension.name,
          label: this.$t(
            `tables.dimensionIds.dimension.${dimension.id}.DEFAULT.title`
          ),
          formatter: (val, key, item) =>
            this.getValueOfDepartmentByDimension(item, dimension),
          sortByFormatted: true,
          filterByFormatted: true,
          sortDirection: "desc",
          class: "font-weight-bold text-left align-middle",
          trClass: "text-center",
          thClass: "h6",
          sortable: true
        });
      });

      return result;
    },
    filteredDepartments() {
      return this.groupedDepartments;
    },
    totalDepartments() {
      return this.filteredDepartments.length;
    },
    groupedDepartments() {
      return !_.isEmpty(this.campaign)
        ? _.keys(_.groupBy(this.campaign.departments, "stakeholderId"))
        : [];
    },
    basicDimensions() {
      return [
        {
          id: this.getConstant("DIMENSION_ELEMENT_PHISHING_EMAIL_SENT"),
          name: "DIMENSION_ELEMENT_PHISHING_EMAIL_SENT",
          class: "success",
          color: "#1BC5BD",
          icon: "sending.svg"
        },
        {
          id: this.getConstant("DIMENSION_ELEMENT_PHISHING_EMAIL_OPENED"),
          name: "DIMENSION_ELEMENT_PHISHING_EMAIL_OPENED",
          class: "info",
          color: "#8950FC",
          icon: "mail-opened.svg"
        },
        {
          id: this.getConstant("DIMENSION_ELEMENT_PHISHING_CLICKED_LINK"),
          name: "DIMENSION_ELEMENT_PHISHING_CLICKED_LINK",
          class: "warning",
          color: "#FFA800",
          icon: "cursor.svg"
        },
        {
          id: this.getConstant("DIMENSION_ELEMENT_PHISHING_SUBMITTED_DATA"),
          name: "DIMENSION_ELEMENT_PHISHING_SUBMITTED_DATA",
          class: "danger",
          color: "#F64E60",
          icon: "write.svg"
        }
      ];
    }
  },
  methods: {
    handleSortChange(sortInfo) {
      this.employees = _.orderBy(
        this.employees,
        element => {
          let field = _.find(
            this.employeeFields,
            el => el.key == sortInfo.sortBy
          );
          var result = null;
          if (field) {
            if (field.formatter) {
              result = field.formatter(null, null, element);
            } else {
              result = element[field.key];
            }
          }

          return result;
        },
        sortInfo.sortDesc ? "desc" : "asc"
      );
      this.currentPage = 1;
      this.loadElements(this.employeesCurrentPage);
    },
    openSubcampaignConfig() {
      this.$nextTick(() => {
        this.$root.$emit(
          "bv::show::modal",
          `modal-template-preview-${this.modalIndex}`,
          `label-${this.modalIndex}`
        );
      });
    },
    getSubcampaignsConfig(subcampaign) {
      const stakeholderId = localStorage.getItem("managedCompanyId");
      axios
        .all([
          getSubcampaign(stakeholderId, this.campaignId, subcampaign.campaignId)
        ])
        .then(
          axios.spread(response => {
            this.subcampaigns = response.data.records;
            if (this.subcampaigns.configuration) {
              this.selectSubcampaign(
                this.subcampaigns.configuration[0],
                this.modalIndex
              );
            }
          })
        );
    },
    selectSubcampaign(configuration, index) {
      this.subcampaignName = configuration.name;
      this.config = configuration;
      this.modalIndex = index;
      this.isSubcampaignSelected = true;
    },
    apiCall(uri, name) {
      this.axios.get(uri).then(response => {
        let a = document.createElement("a");
        a.href =
          "data:" +
          response.data.records.type +
          ";base64," +
          response.data.records.content;
        a.download = name + ".xlsx";
        a.click();
        this.loading = false;
      });
    },
    formattedDate(date) {
      return date ? moment(date).format("lll") : "-";
    },
    getUserData(stakeholderId) {
      var self = this;
      return (
        _.find(
          this.allUsers,
          user => parseInt(user.stakeholderId) === parseInt(stakeholderId)
        ) || {
          avatar: null,
          isBoss: "0",
          name: self.$t("campaigns.deleted.user"),
          stakeholderId: -1,
          surname: ""
        }
      );
    },
    getDepartmentData(stakeholderId) {
      var self = this;
      return (
        _.find(
          this.allDepartments,
          department =>
            parseInt(department.stakeholderId) === parseInt(stakeholderId)
        ) || {
          name: self.$t("campaigns.deleted.department"),
          stakeholderId: -1
        }
      );
    },
    isReported(employee) {
      return (
        extractValueFromProfile(
          Object.keys(this.usersProfiles).length > 1
            ? this.usersProfiles[employee.stakeholderId]
            : this.usersProfiles,
          "DIMENSION_GROUP_PHISHING",
          false,
          "dimensionValue",
          false,
          "DIMENSION_ELEMENT_PHISHING_EMAIL_REPORTED",
          employee.surveyEntityId
        ) === 1
      );
    },
    getLevelByStakeholder(employee) {
      var found = false;
      var index = 3;
      var dimensions = [
        "DIMENSION_ELEMENT_PHISHING_EMAIL_SENT",
        "DIMENSION_ELEMENT_PHISHING_EMAIL_OPENED",
        "DIMENSION_ELEMENT_PHISHING_CLICKED_LINK",
        "DIMENSION_ELEMENT_PHISHING_SUBMITTED_DATA"
      ];
      var value = 0;

      while (found === false && index >= 0) {
        var dimension = dimensions[index];
        index--;
        found =
          extractValueFromProfile(
            Object.keys(this.usersProfiles).length > 1
              ? this.usersProfiles[employee.stakeholderId]
              : this.usersProfiles,
            "DIMENSION_GROUP_PHISHING",
            false,
            "dimensionValue",
            false,
            dimension,
            employee.surveyEntityId
          ) === 1 || false;
      }

      if (found) value = index + 3;

      var cssClass = "disabled";
      if (value == 1) cssClass = "primary";
      else if (value == 2) cssClass = "success";
      else if (value == 3) cssClass = "info";
      else if (value == 4) cssClass = "warning";
      else if (value == 5) cssClass = "danger";

      return {
        value: value,
        class: cssClass
      };
    },
    getValueOfDepartmentByDimension(department, dimension) {
      if (department) {
        var foundDepartment = _.filter(
          this.campaign.departments,
          depart => parseInt(depart.stakeholderId) === parseInt(department)
        );
      }
      var result = 0;
      _.each(foundDepartment, depart => {
        var value = extractValueFromProfile(
          Object.keys(this.departmentsProfiles).length > 1
            ? this.departmentsProfiles[depart.stakeholderId]
            : this.departmentsProfiles,
          "DIMENSION_GROUP_PHISHING",
          false,
          "dimensionValue",
          false,
          dimension.name,
          depart.surveyEntityId
        );
        if (_.isNumber(value)) result += parseInt(value);
      });
      return result;
    },
    getInitials(department) {
      if (department) {
        var initials = "";
        _.each(_.words(department.name), word => {
          initials = initials + word.substring(0, 1);
        });
      } else {
        initials = "N/D";
      }
      return initials;
    },
    getStatus(item) {
      if (
        item.endDate != this.getConstant("NULL_DATE") &&
        moment(item.endDate).isBefore(moment(item.startDate))
      ) {
        return "canceled";
      } else if (moment(item.startDate).isAfter(moment())) {
        return "programmed";
      } else if (
        item.endDate == this.getConstant("NULL_DATE") ||
        moment(item.endDate).isAfter(moment())
      ) {
        return "progress";
      } else {
        return "finished";
      }
    },
    getDepartmentBase(department) {
      return this.getValueOfDepartmentByDimension(department, {
        name: "DIMENSION_ELEMENT_PHISHING_EMAIL_SENT"
      });
    },
    loadElements(actualPage) {
      this.loading = true;
      let stakeholderCompanyId = localStorage.getItem("managedCompanyId");
      let page = actualPage;
      let stakeholders = _.map(
        _.differenceWith(
          _.slice(
            this.employees,
            (page - 1) * this.employeesPerPage,
            page * this.employeesPerPage
          ),
          this.allUsers,
          (obj1, obj2) => obj1.stakeholderId == obj2.stakeholderId
        ),
        "stakeholderId"
      );

      if (_.size(stakeholders) > 0) {
        getStakeholders(
          "companies",
          stakeholderCompanyId,
          "people",
          {
            stakeholdersId: stakeholders
          },
          { name: true, surname: true, profile: false }
        ).then(response => {
          this.allUsers = _.concat(this.allUsers, response.data.records);
          this.loading = false;
          this.currentPage = actualPage;
        });
      } else {
        this.loading = false;
        this.currentPage = actualPage;
      }
    },
    loadData() {
      const stakeholderId = localStorage.getItem("managedCompanyId");
      this.loaded = false;
      this.campaignId = this.$route.params.id;
      axios
        .all([
          getCampaign(stakeholderId, this.campaignId),
          getSubcampaigns(stakeholderId, this.campaignId),
          getHeaders(stakeholderId)
        ])
        .then(
          axios.spread((campaignResp, subcampaignsResp, headersResponse) => {
            this.companyHeaders = headersResponse.data.records;
            this.subcampaigns = subcampaignsResp.data.records;
            this.campaign = campaignResp.data.records;
            this.getSubcampaignsConfig(this.subcampaigns[0]);

            _.each(this.subcampaigns, subcampaign => {
              _.merge(this.employees, subcampaign.employees);
            });
            this.loadElements(this.employeesCurrentPage);
            _.each(this.subcampaigns, subcampaign => {
              _.merge(this.campaign.departments, subcampaign.departments);
            });
            var params = {
              groupDimensions: [this.getConstant("DIMENSION_GROUP_PHISHING")],
              filterDate: "NO"
            };
            var userParams = {
              groupDimensions: [this.getConstant("DIMENSION_GROUP_PHISHING")],
              parentReference: "NO",
              subcampaigns: _.map(
                _.filter(this.subcampaigns, sub => sub.employees.length > 0),
                "campaignId"
              )
            };
            var departmentParams = {
              groupDimensions: [this.getConstant("DIMENSION_GROUP_PHISHING")],
              subcampaigns: _.map(
                _.filter(this.subcampaigns, sub => sub.departments.length > 0),
                "campaignId"
              ),
              parentReference: "NO",
              department: "TRUE"
            };
            axios
              .all([getEntityProfile(stakeholderId, this.campaignId, params)])
              .then(
                axios.spread(profileResp => {
                  let stakeholderCompanyId = localStorage.getItem(
                    "managedCompanyId"
                  );
                  var surveysEntities = _.map(this.employees, "surveyEntityId");

                  var stakeholderDepartmentsIds = _.map(
                    this.campaign.departments,
                    "stakeholderId"
                  );
                  var requests = [];

                  var pager = 0;
                  var limit = 200;

                  while (pager * limit < _.size(stakeholderDepartmentsIds)) {
                    requests.push(
                      getStakeholders(
                        "companies",
                        stakeholderCompanyId,
                        "departments",
                        {
                          stakeholdersId: _.slice(
                            stakeholderDepartmentsIds,
                            pager * limit,
                            (pager + 1) * limit
                          )
                        }
                      )
                    );
                    pager++;
                  }
                  this.allDepartments = [];
                  axios.all(requests).then(
                    axios.spread((...responses) => {
                      _.each(responses, response => {
                        this.allDepartments.push(...response.data.records);
                      });
                    })
                  );

                  pager = 0;
                  limit = 50;
                  requests = [];
                  while (pager * limit < _.size(surveysEntities)) {
                    requests.push(
                      getEntityProfile(
                        stakeholderId,
                        this.campaignId,
                        _.merge(
                          {
                            surveyEntities: _.slice(
                              surveysEntities,
                              pager * limit,
                              (pager + 1) * limit
                            )
                          },
                          userParams
                        )
                      )
                    );
                    pager++;
                  }
                  axios.all(requests).then(
                    axios.spread((...responses) => {
                      this.usersProfiles = {};
                      _.each(responses, usersResponse => {
                        this.usersProfiles = _.merge(
                          this.usersProfiles,
                          usersResponse.data.records
                        );
                      });
                      // FROM HERE

                      var departmentsSurveysEntities = _.map(
                        this.campaign.departments,
                        "surveyEntityId"
                      );
                      var pager = 0;
                      var limit = 50;
                      var requests = [];
                      while (
                        pager * limit <
                        _.size(departmentsSurveysEntities)
                      ) {
                        requests.push(
                          getEntityProfile(
                            stakeholderId,
                            this.campaignId,
                            _.merge(
                              {
                                surveyEntities: _.slice(
                                  departmentsSurveysEntities,
                                  pager * limit,
                                  (pager + 1) * limit
                                )
                              },
                              departmentParams
                            )
                          )
                        );
                        pager++;
                      }
                      axios.all(requests).then(
                        axios.spread((...deptResponses) => {
                          this.departmentsProfiles = {};
                          _.each(deptResponses, departmentsProfilesResp => {
                            this.departmentsProfiles = _.merge(
                              this.departmentsProfiles,
                              departmentsProfilesResp.data.records
                            );
                          });

                          // TO HERE
                          _.each(this.basicDimensions, dimension => {
                            var base = false;
                            var value = false;
                            var rawValue = false;
                            if (
                              dimension.name ===
                              "DIMENSION_ELEMENT_PHISHING_EMAIL_SENT"
                            ) {
                              rawValue = extractValueFromProfile(
                                profileResp.data.records,
                                "DIMENSION_GROUP_PHISHING",
                                "SURVEY_KYMATIO_PHISHING",
                                "dimensionValue",
                                false,
                                dimension.name,
                                this.campaign.surveyEntityId
                              );
                              this.base = rawValue !== false ? rawValue : 0;
                              base = this.companySelected.peopleCount;
                              value = rawValue
                                ? (rawValue /
                                    this.companySelected.peopleCount) *
                                  100
                                : 0;
                            } else {
                              rawValue = extractValueFromProfile(
                                profileResp.data.records,
                                "DIMENSION_GROUP_PHISHING",
                                "SURVEY_KYMATIO_PHISHING",
                                "dimensionValue",
                                false,
                                dimension.name,
                                this.campaign.surveyEntityId
                              );
                              base = false;
                              value = rawValue
                                ? (rawValue / this.base) * 100
                                : 0;
                            }
                            this.dimensions.push({
                              id: parseInt(this.getConstant(dimension.name)),
                              name: dimension.name,
                              class: dimension.class,
                              icon: dimension.icon,
                              value: value,
                              color: dimension.color,
                              base: base,
                              rawValue: rawValue !== false ? rawValue : 0
                            });
                          });
                          this.loaded = true;
                        })
                      );
                    })
                  );
                })
              );
          })
        );
    },
    chartOptions(dimension) {
      var self = this;
      return {
        chart: {
          height: 250,
          type: "radialBar"
        },
        colors: [dimension.color + "2b"],
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%"
            },
            track: {
              background: dimension.color + "2b"
            },
            dataLabels: {
              value: {
                formatter: () => {
                  if (
                    dimension.name === "DIMENSION_ELEMENT_PHISHING_EMAIL_SENT"
                  )
                    return dimension.rawValue;
                  else
                    return (
                      dimension.rawValue +
                      "/" +
                      (dimension.base !== false ? dimension.base : this.base)
                    );
                },
                fontSize: "2.3rem",
                offsetY: 10
              },
              name: {
                fontSize: "1.5rem",
                color: "#000",
                offsetY: -10
              }
            }
          }
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: true,
          theme: "light",
          y: {
            formatter: () => {
              return self.$n(
                dimension.rawValue /
                  (dimension.base !== false ? dimension.base : this.base),
                "percent"
              );
            }
          }
        },
        fill: {
          type: "solid",
          colors: [dimension.color]
        },
        stroke: {
          lineCap: "round"
        },
        labels: [
          this.$t(`tables.dimensionIds.dimension.${dimension.id}.DEFAULT.title`)
        ]
      };
    }
  },
  mounted() {
    if (this.isConstantsActive) this.loadData();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadData();
    }
  }
};
</script>
