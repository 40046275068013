<template>
  <div>
    <div class="subheader py-2 py-lg-6 subheader-transparent">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Page Title-->
        <h5 class="text-dark font-weight-bold my-1 mr-5">
          {{ $t("admin.form.create_tag_value", { name: tag.name }) }}
        </h5>
        <!--end::Page Title-->
        <b-button
          @click="
            $router.push({ name: 'TagView', params: { id: $route.params.id } })
          "
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder mr-2 text-uppercase"
        >
          <div class="d-flex">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
      </div>
    </div>
    <basic-form
      :form="form"
      :fields="fields"
      :save="save"
      :error="error"
      :errors="errors"
      :isSubmitting="isSubmitting"
      v-if="loaded"
    />
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { insertTagValue } from "@/api/admin/stakeholders.api";
import { validText } from "@/utils/validators";
import BasicForm from "@/components/form/BasicForm.component";
import { getTags } from "@/api/admin/stakeholders.api";

export default {
  name: "TagValueCreate",
  components: {
    BasicForm
  },
  data() {
    return {
      form: {
        tagValue: "",
        tagStakeholders: []
      },
      errors: [],
      error: false,
      isSubmitting: false,
      tag: false
    };
  },
  computed: {
    ...mapGetters("Admin", [
      "areUsersLoaded",
      "users",
      "areDepartmentsLoaded",
      "departments"
    ]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    fields() {
      return [
        {
          id: "tagValue",
          required: true,
          type: "text",
          validation: validText(this.form.tagValue),
          maxLength: 255
        },
        {
          id: "tagStakeholders",
          required: true,
          type: "table_select",
          size: "12",
          options: this.tableUsers,
          fields: this.tableFields,
          perPage: 10
        }
      ];
    },
    tableUsers() {
      return _.map(this.users, user => {
        return {
          id: user.stakeholderId,
          name: user.name + " " + user.surname,
          email: user.email,
          selected: false
        };
      });
    },
    tableFields() {
      return [
        {
          key: "selected",
          label: this.$t(`admin.form.selected`),
          thClass: "col-2",
          sortable: true
        },
        {
          key: "name",
          label: this.$t(`admin.form.name.label`),
          sortable: true,
          stickyColumn: true,
          isRowHeader: true,
          thClass: "col-5"
        },
        {
          key: "email",
          label: this.$t(`admin.form.email.label`),
          thClass: "col-5",
          sortable: true
        }
      ];
    },
    loaded() {
      return this.areUsersLoaded;
    }
  },
  methods: {
    ...mapActions("Admin", ["loadDepartments", "reset", "loadUsers"]),
    save() {
      if (_.size(this.form.tagStakeholders) > 0) {
        this.isSubmitting = true;
        let requests = [];
        this.errors = [];
        _.each(this.form.tagStakeholders, stakeholder => {
          requests.push(
            insertTagValue(this.$route.params.id, stakeholder, {
              tag: this.form.tagValue
            })
          );
        });

        axios
          .all(requests)
          .then(() => {
            this.isSubmitting = false;
            this.$router.push({
              name: "TagView",
              params: { id: this.$route.params.id }
            });
          })
          .catch(error => {
            this.$swal("", error);
          });
      } else {
        this.$swal(this.$t("errors.no_empty_users"));
      }
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.areUsersLoaded) this.loadUsers();
    getTags(localStorage.getItem("managedCompanyId"), {
      tagId: this.$route.params.id,
      tagValue: this.$route.params.value
    }).then(response => {
      this.loading = false;
      let tag = _.first(response.data.records);
      this.tag = tag;
    });
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadUsers();
      }
    },
    areUsersLoaded(value) {
      if (this.isConstantsActive && value === false) this.loadUsers();
    }
  }
};
</script>
