<template>
  <!--begin::Login-->
  <div
    class="
      login login-2
      d-flex
      flex-column flex-lg-row flex-row-fluid
      bg-white
      fill
    "
  >
    <!--begin::Aside-->
    <div
      class="
        login-aside
        position-relative
        overflow-hidden
        order-2 order-lg-1
        d-flex
        flex-column-fluid flex-lg-row-auto
        py-9
        px-7
        py-lg-13
        px-lg-35
      "
    >
      <!--begin: Aside Container-->
      <div class="d-flex flex-row-fluid flex-column justify-content-between">
        <!--begin::Logo-->
        <a href="#" class="text-center pt-8">
          <b-img-lazy
            src="@/assets/images/login/ky.png"
            class="max-w-70vw"
            alt=""
          />
        </a>
        <!--end::Logo-->
        <!--begin::Aside body-->
        <div class="d-flex flex-column-fluid flex-column flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <!--begin::Form-->
            <b-form
              @submit.stop.prevent="doAcceptConditions(user)"
              class="form mt-9"
            >
              <!--begin::Form group-->
              <b-form-group
                class="fv-plugins-icon-container text-left"
                v-show="status === 'conditions'"
              >
                <b-form-checkbox
                  v-model="user.termsOfUse"
                  :state="user.termsOfUse"
                  class="mb-3 mr-1"
                  size="lg"
                  :required="true"
                  style="display: inline-block"
                >
                </b-form-checkbox>
                <div style="display: inline-block">
                  <a
                    @click="$bvModal.show('modal-terms')"
                    href="javascript:void(0)"
                  >
                    {{ $t("login.conditions.termsOfUse") }}
                  </a>
                </div>
                <div class="clearfix" />
                <b-form-checkbox
                  v-model="user.privacyPolicy"
                  class="mb-3 mr-1"
                  id="conditions"
                  :state="user.privacyPolicy"
                  size="lg"
                  :required="true"
                  style="display: inline-block"
                >
                </b-form-checkbox>
                <div style="display: inline-block">
                  <a
                    @click="$bvModal.show('modal-privacy')"
                    href="javascript:void(0)"
                  >
                    {{ $t("login.conditions.privacyPolicy") }}
                  </a>
                </div>
                <b-form-invalid-feedback
                  id="conditions-feedback"
                  v-show="error === true"
                >
                  {{ callGetError(status) }}
                </b-form-invalid-feedback>
              </b-form-group>
              <!--end::Form group-->
              <!--begin::Action-->
              <div class="text-center mt-7">
                <b-button
                  type="submit"
                  pill
                  variant="primary"
                  class="btn font-weight-bolder font-size-h6 px-8 py-4 my-7"
                >
                  {{ $t("login.buttons.next") }}
                </b-button>
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::Form-->
          </div>
          <!--end::Signin-->
        </div>
        <div class="d-flex flex-column-fluid flex-column">
          <div style="display: inline-block" class="mt-auto">
            <a
              @click="$bvModal.show('modal-privacy')"
              href="javascript:void(0)"
            >
              {{ $t("footer.privacy") }}
            </a>
            |
            <a @click="$bvModal.show('modal-terms')" href="javascript:void(0)">
              {{ $t("footer.termsOfUse") }}
            </a>
          </div>
        </div>
        <!--end::Aside body-->
      </div>
      <!--end: Aside Container-->
    </div>

    <!--begin::Aside-->
    <!--begin::Content-->
    <div
      class="
        content
        order-1 order-lg-2
        flex-column-row-auto flex-grow-1
        pt-md-0
        pb-40
        bgi-no-repeat bgi-position-y-bottom bgi-position-x-center
        login-right
      "
    >
      <!--begin::Content body-->
      <div class="d-flex flex-center pb-lg-40 pt-lg-0 pt-md-0 pb-40">
        <div
          class="
            d-flex
            flex-column
            justify-content-center
            text-center
            pt-xl-30 pt-lg-20
            pb-sm-10
          "
        >
          <h1
            class="display4 font-weight-bolder my-7 text-dark"
            style="font-size: 4rem !important"
          >
            <span
              v-for="(value, index) in $t('login.header')"
              v-bind:key="index"
              >{{ value }}<br
            /></span>
          </h1>
          <p
            class="
              font-weight-bolder font-size-h4 font-size-lg
              text-dark
              opacity-70
              pb-lg-3 pb-sm-40 pb-10
            "
            style="font-size: 2rem !important; font-weight: lighter !important"
          >
            <span
              v-for="(value, index) in $t('login.subheader')"
              v-bind:key="index"
              >{{ value }}<br
            /></span>
          </p>
        </div>
      </div>
      <!--end::Content body-->
    </div>
    <b-modal
      id="modal-privacy"
      hide-footer
      size="xl"
      scrollable
      :title="$t('legal.privacy.title')"
    >
      <div
        class="d-block mb-8 pt-1 pb-4 pr-8 pl-8"
        v-for="(content, index) in $t('legal.privacy.contents')"
        :key="content.title"
      >
        <h3>{{ index + 1 }}- {{ content.title }}</h3>
        <p v-html="content.content"></p>
        <span
          v-for="(child, subindex) in content.children"
          v-bind:key="child.title"
        >
          <h6>
            {{ index + 1 }}.{{ subindex + 1 }} - <span v-html="child.title" />
          </h6>
          <span v-html="child.content" />
        </span>
      </div>
    </b-modal>
    <b-modal
      id="modal-terms"
      hide-footer
      size="xl"
      scrollable
      :title="$t('legal.terms.title')"
    >
      <div
        class="d-block mb-8 pt-1 pb-4 pr-8 pl-8"
        v-for="(content, index) in $t('legal.terms.contents')"
        :key="content.title"
      >
        <h3>{{ index + 1 }}- {{ content.title }}</h3>
        <p v-html="content.content"></p>
        <span
          v-for="(child, subindex) in content.children"
          v-bind:key="child.title"
        >
          <h6>
            {{ index + 1 }}.{{ subindex + 1 }} - <span v-html="child.title" />
          </h6>
          <span v-html="child.content" />
        </span>
      </div>
    </b-modal>
    <!--end::Content-->
  </div>
</template>

<script>
import store from "@/store";
import _ from "lodash";
import { checkToken, acceptConditions } from "@/api/auth.api";
import { getConstants } from "@/api/misc.api";
import { mapGetters } from "vuex";

import { getError } from "@/utils/helpers";

export default {
  name: "Autologin",
  data() {
    return {
      user: {
        termsOfUse: null,
        privacyPolicy: null
      },
      status: "",
      tempData: {},
      error: false
    };
  },
  computed: {
    ...mapGetters("Auth", ["defaultView"])
  },
  methods: {
    callGetError(code, params) {
      return getError(code, params);
    },
    doAcceptConditions(user) {
      if (user.termsOfUse === true && user.privacyPolicy === true) {
        var conditions = {
          termsOfUse: this.user.termsOfUse ? 1 : 0,
          privacyPolicy: this.user.privacyPolicy ? 1 : 0,
          token: this.tempData.token
        };
        acceptConditions(conditions, {
          headers: { Authorization: `Bearer ${this.tempData.token}` }
        }).then(() => {
          checkToken({ token: this.$route.query.token }).then(response => {
            this.doAutoLogin(response.data.records);
          });
        });
      } else {
        this.error = true;
      }
    },
    doAutoLogin(data) {
      getConstants("SERVICETYPE_").then(servicesResp => {
        let profiles = _.words(_.startCase(_.keys(data.profiles)));
        localStorage.setItem("token", data.token);
        store.dispatch("Auth/setToken");
        localStorage.setItem("stakeholderId", data.stakeholderUserId);
        localStorage.setItem("companyId", data.stakeholderCompanyId);
        localStorage.setItem("companyTypeId", data.companyTypeId);
        localStorage.setItem("profiles", profiles);
        store.dispatch("Auth/setProfiles", data.profiles);
        localStorage.setItem("federated", data.federated);
        localStorage.setItem("languages", JSON.stringify(data.languages));
        localStorage.setItem(
          "user_services",
          JSON.stringify(data.services.USER)
        );
        store.dispatch("Auth/setServices", {
          services: servicesResp.data.records,
          userServices: data.services.USER
        });
        store.dispatch("Auth/changeLocalLanguage", data.locale.toLowerCase());
        if (_.includes(profiles, "USER")) {
          this.$router.push({ name: "Dashboard" });
        } else if (_.includes(profiles, "CONTROLLER")) {
          this.$router.push({ name: "ControllerDashboard" });
        } else if (_.includes(profiles, "ADMIN")) {
          this.$router.push({ name: "AdminDashboard" });
        }
      });
    }
  },
  beforeCreate() {
    // localStorage.setItem("token", this.$route.query.token);
    var params = {};
    if (localStorage.getItem("language")) {
      params.locale = localStorage.getItem("language");
    }
    params.token = this.$route.query.token;
    checkToken(params)
      .then(response => {
        if (
          response.data.records.termsOfUse === 1 &&
          response.data.records.privacyPolicy === 1
        ) {
          this.doAutoLogin(response.data.records);
        } else {
          this.tempData = response.data.records;
          this.status = "conditions";
        }
      })
      .catch(() => {
        this.$router.push("/");
      });
  }
};
</script>

<style src="@/assets/scss/login.scss" lang="scss" scoped></style>

<style lang="scss">
.max-w-70vw {
  max-width: 70%;
}
</style>
